import SmartPKI from './smartca-pki-lib.esm'

class PKIDriver {
  webApiAddr = undefined;
  webApiPort = undefined;
  portIndex = undefined;
  netonexPorts = [];
  smartPKI = undefined;
  constructor(proto) {
    this.smartPKI = new SmartPKI({
      protocol: proto
    })
  }

  /**
   * pkcs1签名
   */
  getPkcs1String(data, dataType) {
    const cryptoInterface = 1 // 1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
    const defaultSelect = 0 // 1:默认选择唯一的那张证书， 0：不设置
    const noCertErr = 0 // 1:没有证书的时候，不再弹出空的证书选择列表， 0：不设置
    const pwd = ''
    const { smartPKI } = this
    if (dataType == 'string') {
      return this.smartPKI.pkcs1String(
        data,
        undefined,
        undefined,
        'sm3',
        undefined,
        defaultSelect,
        noCertErr
      )
    }
    if (dataType == 'digest') {
      return smartPKI.pkcs1Digest(
        data,
        undefined,
        undefined,
        undefined,
        undefined,
        cryptoInterface,
        defaultSelect,
        noCertErr
      )
    }
    if (dataType == 'base64') {
      return smartPKI.pkcs1Base64(
        data,
        undefined,
        undefined,
        undefined,
        pwd,
        cryptoInterface,
        defaultSelect,
        noCertErr
      )
    }
    if (dataType == 'utf8String') {
      return smartPKI.pkcs1Utf8String(
        data,
        undefined,
        undefined,
        undefined,
        pwd,
        cryptoInterface,
        defaultSelect,
        noCertErr
      )
    }
    return Promise.reject({ errorCode: 9001, errorMsg: '类型不支持' })
  }

  /**
   * 校验用户PIN码
   * @param {String} sDeviceSN  设备号
   * @param {String} sUserPin  Pin码
   * @param {Function} checkPinCallback 校验回调
   * @param {Function} callValue 校验回调返回参数  非必填
   */
  verifyUserPin(sDeviceSN, sUserPin) {
    return this.smartPKI.verifyUserPin(sDeviceSN, sUserPin)
  }

  /**
   *解析证书Base64
   *
   */
  getCertInfo() {
    const keyUsage = 32 // |16;  //32签名证书，16加密证书，32|16 枚举所有
    const cryptoInterface = 1 // 1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
    return this.smartPKI.getCertInfo(
      undefined,
      undefined,
      undefined,
      undefined,
      keyUsage,
      cryptoInterface,
      undefined,
      undefined
    )
  }

  /**
   * 静默获取pkcs1签名
   * @param data  				[string]	需要签名的原文数据
   * @param cert  	 			[string]	可以为空；签名证书base64，
   * @param finger 				[string]	可以为空；证书指纹字符串, 可以是SHA1的结果, 也可以是MD5的结果 ，不区分大小写。当cert和finger都存在时，以cert为主。
   * @param alg 					[string]	可以为空；签名算法（RSA证书默认是SHA1，SM2证书固定SM3），仅对RSA证书有效，用与解决部分CA证书仅支持sha256签名算法。需要key支持相应的签名算法
   * @param pwd					[string]    PIN码
   * @param cryptoInterface  		[int]   	可以为0.  设置证书枚举过滤算法，默认枚举所有算法证书。1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
   * @param callback 	回调函数，上层传入
   * @param {Object} callValue 校验回调返回参数  非必填
   */
  pkcs1Silent(data, cert, finger, alg, pwd, cryptoInterface) {
    return this.smartPKI.pkcs1Silent(data, cert, finger, alg, pwd, cryptoInterface)
  }

  /** 获取设备序列号 */
  getAllDeviceSN() {
    return this.smartPKI.getAllDeviceSN()
  }

  /**
     * 生成密钥对
     * @param {*} sDeviceSN  设备号
     * @param {*} sContainerName 容器名称
     * @param {*} ikeyType 1： 标识rsa1024 2 ：标识rsa2048 3： 标识sm2   默认3
     * @param {*} generateKeyPairCall
     */
  generateKeyPair(sDeviceSN, sContainerName, ikeyType) {
    return this.smartPKI.generateKeyPair(sDeviceSN, sContainerName, ikeyType)
  }

  /**
   * 产生PKCS10
   * @param {*} sDeviceSN 设备号
   * @param {*} sContainerName 容器名
   * @param {*} sDN 证书DN
   * @param {*} generateP10Call 回调
   */
  exportPKCS10(sDeviceSN, sContainerName, sDN) {
    return this.smartPKI.exportPKCS10(sDeviceSN, sContainerName, sDN)
  }
  /**
   * 导入加密证书
   * @param {*} sDeviceSN 设备号
   * @param {*} sContainerName 容器名称
   * @param {*} sCert 加密证书
   * @param {*} sPriKeyCipher 加密私钥
   */

  importEncCert(sDeviceSN, sContainerName, sCert, sPriKeyCipher) {
    return this.smartPKI.importEncCert(sDeviceSN, sContainerName, sCert, sPriKeyCipher)
  }

  /**
    * 导入签名证书
    * @param {*} sDeviceSN 设备号
    * @param {*} sContainerName 容器名称
    * @param {*} sCert 签名证书
    * @param {*} importSignCertCall 成功或失败回调
    */
  importSignCert(sDeviceSN, sContainerName, sCert) {
    return this.smartPKI.importSignCert(sDeviceSN, sContainerName, sCert)
  }

  /**
*删除文件
*/
  deleteUkeyFile(sDeviceSN, sFileName) {
    //删除ukey 中文件
    return this.smartPKI.DeleteUkeyFile(sDeviceSN, sFileName);
  }

  /**
*获取ukey 中所有文件
*/
  getAllFiles(sDeviceSN) {
    //获取ukey 中所有文件
    return this.smartPKI.GetAllFiles(sDeviceSN);
  }
    /**
*写文件
*/
writeFile(sDeviceSN, sFileName, sContent) {
  //key的序列号,文件名称,写入内容,回调
  return this.smartPKI.writeFile(sDeviceSN, sFileName, sContent);
}

/**
*读文件
*/
readFile(sDeviceSN, sFileName) {
  //key的序列号,文件名称,写入内容,回调
  return this.smartPKI.readFile(sDeviceSN, sFileName);
}
  /**
   * 解锁用户Pin码
   */
  unlockUserPass({ sDeviceSN, sAdminPass, sNewUserPass }) {
    return this.smartPKI.unlockUserPass(sDeviceSN, sAdminPass, sNewUserPass)
  }
  /** 写文件base64 */
  writeFileBase64(sDeviceSN, sFileName, sContent) {
    return this.smartPKI.writeFileFromBase64(sDeviceSN, sFileName, sContent)
  }
}

/** 根据名称上获取主题上的属性 */
export function getProBySubject(subject, name) {
  const list = subject.split(',')
  let result
  for (let i = 0; i < list.length; i++) {
    const item = list[i].replace(/\s*=\s*/g, '=')
    const [key, value] = item.split('=')
    if (name.toLocaleLowerCase() === key.toLocaleLowerCase()) {
      result = value
      break
    }
  }
  return result
}
export default PKIDriver
