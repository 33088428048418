/**
 *create by 2012-08-25 pm 17:48
 *@author hexinglun@gmail.com
 *BASE64 Encode and Decode By UTF-8 unicode
 *可以和java的BASE64编码和解码互相转化
 */
const BASE64_MAPPING = [
  'A', 'B', 'C', 'D', 'E',
  'F', 'G', 'H', 'I', 'J',
  'K', 'L', 'M', 'N', 'O',
  'P', 'Q', 'R', 'S', 'T',
  'U', 'V', 'W', 'X', 'Y',
  'Z', 'a', 'b', 'c', 'd',
  'e', 'f', 'g', 'h', 'i',
  'j', 'k', 'l', 'm', 'n',
  'o', 'p', 'q', 'r', 's',
  't', 'u', 'v', 'w', 'x',
  'y', 'z', '0', '1', '2',
  '3', '4', '5', '6', '7',
  '8', '9', '+', '/'
]

/**
 *ascii convert to binary
 */
const _toBinary = function (ascii) {
  const binary = new Array()
  while (ascii > 0) {
    const b = ascii % 2
    ascii = Math.floor(ascii / 2)
    binary.push(b)
  }
  /*
	var len = binary.length;
	if(6-len > 0){
		for(var i = 6-len ; i > 0 ; --i){
			binary.push(0);
		}
	} */
  binary.reverse()
  return binary
}

/**
 *binary convert to decimal
 */
const _toDecimal = function (binary) {
  let dec = 0
  let p = 0
  for (let i = binary.length - 1; i >= 0; --i) {
    const b = binary[i]
    if (b == 1) {
      dec += Math.pow(2, p)
    }
    ++p
  }
  return dec
}

/**
 *unicode convert to utf-8
 */
const _toUTF8Binary = function (c, binaryArray) {
  const mustLen = 8 - (c + 1) + (c - 1) * 6
  const fatLen = binaryArray.length
  let diff = mustLen - fatLen
  while (--diff >= 0) {
    binaryArray.unshift(0)
  }
  const binary = []
  let _c = c
  while (--_c >= 0) {
    binary.push(1)
  }
  binary.push(0)
  let i = 0
  const len = 8 - (c + 1)
  for (; i < len; ++i) {
    binary.push(binaryArray[i])
  }

  for (let j = 0; j < c - 1; ++j) {
    binary.push(1)
    binary.push(0)
    let sum = 6
    while (--sum >= 0) {
      binary.push(binaryArray[i++])
    }
  }
  return binary
}

// (function () {

const BASE64 = {
  /**
   *BASE64 Encode
   */
  encoder: function (str) {
    const base64_Index = []
    let binaryArray = []
    for (let i = 0, len = str.length; i < len; ++i) {
      const unicode = str.charCodeAt(i)
      const _tmpBinary = _toBinary(unicode)
      if (unicode < 0x80) {
        let _tmpdiff = 8 - _tmpBinary.length
        while (--_tmpdiff >= 0) {
          _tmpBinary.unshift(0)
        }
        binaryArray = binaryArray.concat(_tmpBinary)
      } else if (unicode >= 0x80 && unicode <= 0x7ff) {
        binaryArray = binaryArray.concat(_toUTF8Binary(2, _tmpBinary))
      } else if (unicode >= 0x800 && unicode <= 0xffff) {
        // UTF-8 3byte
        binaryArray = binaryArray.concat(_toUTF8Binary(3, _tmpBinary))
      } else if (unicode >= 0x10000 && unicode <= 0x1fffff) {
        // UTF-8 4byte
        binaryArray = binaryArray.concat(_toUTF8Binary(4, _tmpBinary))
      } else if (unicode >= 0x200000 && unicode <= 0x3ffffff) {
        // UTF-8 5byte
        binaryArray = binaryArray.concat(_toUTF8Binary(5, _tmpBinary))
      } else if (unicode >= 4000000 && unicode <= 0x7fffffff) {
        // UTF-8 6byte
        binaryArray = binaryArray.concat(_toUTF8Binary(6, _tmpBinary))
      }
    }

    let extra_Zero_Count = 0
    for (let i = 0, len = binaryArray.length; i < len; i += 6) {
      const diff = i + 6 - len
      if (diff == 2) {
        extra_Zero_Count = 2
      } else if (diff == 4) {
        extra_Zero_Count = 4
      }
      // if(extra_Zero_Count > 0){
      //	len += extra_Zero_Count+1;
      // }
      let _tmpExtra_Zero_Count = extra_Zero_Count
      while (--_tmpExtra_Zero_Count >= 0) {
        binaryArray.push(0)
      }
      base64_Index.push(_toDecimal(binaryArray.slice(i, i + 6)))
    }

    let base64 = ''
    for (let i = 0, len = base64_Index.length; i < len; ++i) {
      base64 += BASE64_MAPPING[base64_Index[i]]
    }

    for (let i = 0, len = extra_Zero_Count / 2; i < len; ++i) {
      base64 += '='
    }
    return base64
  },
  /**
   *BASE64  Decode for UTF-8
   */
  decoder: function (_base64Str) {
    const _len = _base64Str.length
    let extra_Zero_Count = 0
    /**
     *计算在进行BASE64编码的时候，补了几个0
     */
    if (_base64Str.charAt(_len - 1) == '=') {
      // alert(_base64Str.charAt(_len-1));
      // alert(_base64Str.charAt(_len-2));
      if (_base64Str.charAt(_len - 2) == '=') {
        // 两个等号说明补了4个0
        extra_Zero_Count = 4
        _base64Str = _base64Str.substring(0, _len - 2)
      } else {
        // 一个等号说明补了2个0
        extra_Zero_Count = 2
        _base64Str = _base64Str.substring(0, _len - 1)
      }
    }

    let binaryArray = []
    for (var i = 0, len = _base64Str.length; i < len; ++i) {
      const c = _base64Str.charAt(i)
      for (let j = 0, size = BASE64_MAPPING.length; j < size; ++j) {
        if (c == BASE64_MAPPING[j]) {
          const _tmp = _toBinary(j)
          /* 不足6位的补0 */
          const _tmpLen = _tmp.length
          if (6 - _tmpLen > 0) {
            for (let k = 6 - _tmpLen; k > 0; --k) {
              _tmp.unshift(0)
            }
          }
          binaryArray = binaryArray.concat(_tmp)
          break
        }
      }
    }

    if (extra_Zero_Count > 0) {
      binaryArray = binaryArray.slice(0, binaryArray.length - extra_Zero_Count)
    }

    let unicode = []
    let unicodeBinary = []
    for (let i = 0, len = binaryArray.length; i < len;) {
      if (binaryArray[i] == 0) {
        unicode = unicode.concat(_toDecimal(binaryArray.slice(i, i + 8)))
        i += 8
      } else {
        let sum = 0
        while (i < len) {
          if (binaryArray[i] == 1) {
            ++sum
          } else {
            break
          }
          ++i
        }
        unicodeBinary = unicodeBinary.concat(
          binaryArray.slice(i + 1, i + 8 - sum)
        )
        i += 8 - sum
        while (sum > 1) {
          unicodeBinary = unicodeBinary.concat(binaryArray.slice(i + 2, i + 8))
          i += 8
          --sum
        }
        unicode = unicode.concat(_toDecimal(unicodeBinary))
        unicodeBinary = []
      }
    }
    return unicode
  }
}

const container = document.getElementsByTagName('head')[0]

/**
 *
 * @param {{
 *   url: string;
 *   data?: any;
 *   success?:(result) => void
 *   error?:() => void
 *   callbackParameter?: string
 *   callbackFn: string
 * }} options
 * @returns
 */
function jsonp (options) {
  if (!options || !options.url) {
    return
  }
  const {
    data = {},
    url,
    callbackFn,
    callbackParameter,
    error,
    success
  } = options
  data[callbackParameter || 'callback'] = callbackFn
  data.timestamp = new Date().getTime()
  let newUrl = url.indexOf('?') > 0 ? `${url}&` : `${url}?`
  const params = Object.keys(data).map(
    (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
  )
  newUrl = `${newUrl}${params.join('&')}`
  const scriptNode = document.createElement('script')
  scriptNode.src = newUrl
  scriptNode.type = 'text/javascript'

  scriptNode.onerror = function (e) {
    error && error(e)
    cleanUp()
  }
  scriptNode.onabort = function (e) {
    error && error(e)
    cleanUp()
  }
  container.appendChild(scriptNode)

  function cleanUp () {
    container.removeChild(scriptNode)
    if (window[callbackFn]) {
      delete window[callbackFn]
    }
  }

  window[callbackFn] = function (ret) {
    success && success(ret)
    cleanUp()
  }
}

const CommponResponse = {
  /**
   * 通用成功响应
   * @param {*} result 结果
   * @returns
   * @description status为1代表失败,errorCode为特定失败码
   */
  OK (result) {
    return {
      errorCode: 0,
      errorMsg: '',
      result: result,
      status: RESPONSE_STATUS.OK
    }
  },
  /**
   * 通用失败响应
   * @param {*} errCode 错误码
   * @param {*} errString 错误信息
   * @description status为0代表成功
   */
  FAIL (errCode, errString) {
    return {
      errorCode: errCode,
      errorMsg: errString,
      result: 0,
      status: RESPONSE_STATUS.FAIL
    }
  }
}

/** 响应状态枚举 */
const RESPONSE_STATUS = {
  /** 成功 */
  OK: 1,
  /** 失败 */
  FAIL: 0
}

/**
 * 异步请求方法
 * @param {{
 *   url: string;
 *   data?: any;
 *   callbackFn: string;
 *   success: any;
 *   error: any;
 *   async: boolean;
 *   method: 'GET' | 'POST'
 * }} options
 * @returns
 */
function request (options) {
  if (!options || !options.url) {
    return
  }
  const {
    data = {},
    url,
    async = true,
    success,
    error,
    method = 'POST',
    callbackFn
  } = options
  // 是否使用get请求
  const isGet = method == 'GET'
  let newUrl = url
  // get请求参数拼接再url上
  if (isGet) {
    newUrl = url.indexOf('?') > 0 ? `${url}&` : `${url}?`
    data.callback = callbackFn
    data.timestamp = new Date().getTime()
    const params = Object.keys(data).map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
    )
    newUrl = `${newUrl}${params.join('&')}`
  }
  let result
  const xhr = new XMLHttpRequest()
  xhr.open(method, newUrl, async)
  xhr.onload = function () {
    if (this.status == 200) {
      // get请求本质上走的还是jsonp，需要自行处理响应
      const res = isGet ? this.responseText.slice(callbackFn.length + 1, this.responseText.length - 3) : this.responseText
      result = JSON.parse(res)
      success && success(result)
    }
  }
  xhr.onerror = function () {
    error && error()
  }
  xhr.onabort = function () {
    error && error()
  }
  try {
    isGet ? xhr.send() : xhr.send(JSON.stringify(data))
  } catch (err) {
    console.error('服务连接失败', err)
  }
  return result
}

/* 错误码
    8000		//向本地http服务发送请求失败
    8001		//非法消息
    8002		//签名控件初始化失败
    8003		//COM接口创建失败
    8004		//用户取消选择证书
    8005		//未知错误
    8006		//Netonex接口调用失败
    8007		//浏览器中没有证书
    8008		//传入的原数据为空
    8009  		//PKCS1签名失败
    8010 		//创建证书实例失败
    8011 		//PKCS1签名失败
    8012 		//数字信封失败
    8013 		//数字信封解密失败
*/

class SmartCAPKI {
  constructor (options = {}) {
    const { protocol } = options
    this.options = { ...options }
    if (protocol === 'https') {
      this.webApiAddr = 'https://127.0.0.1'
      this.webApiPort = '9690'
      this.portIndex = 0
      this.netonexPorts ='9690'
    } else {
      this.webApiAddr = 'http://127.0.0.1'
      this.webApiPort = '9630'
      this.portIndex = 0
      this.netonexPorts = '9630'
    }
    // 本地https服务相关信息

    this.webApiIndex = '/onecertx'
    this.webNetonexIndex = '/netonex'

    // Get请求最大长度
    this.MAX_PARAM_LEN = 800

    // this.getWebApiPort()
  }

  /** base64编码 */
  base64Encode (val) {
    return BASE64.encoder(val)
  }

  /** base64解码 */
  base64Decode (val) {
    return BASE64.decoder(val)
  }

  static useOcx (smartCAIE) {
    /** 对象不存在且不是支持的对象，设为空 */
    if (!!smartCAIE || !smartCAIE.CONTROL_MAP) {
      SmartCAPKI.prototype.smartCAIE = undefined
      return false
    }
    SmartCAPKI.prototype.smartCAIE = smartCAIE
    return true
  }

  /**
   * 创建业务流水号(16位随机数)
   * @private
   */
  createSN () {
    const arg = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '0'
    ]
    const res = []
    for (let i = 0; i < 16; i++) {
      const p = parseInt(Math.random() * 35 + 0)
      res.push(arg[p])
    }
    return res.join('')
  }

  /**
   * 切割base64参数
   * @param base64
   * @returns {Array}
   */
  cutParam (base64) {
    const _this = this
    const max = _this.MAX_PARAM_LEN
    const len = base64.length

    if (len <= max) {
      return [base64]
    }

    let total = 0

    if (len % max > 0) {
      total = parseInt(len / max) + 1
    } else {
      total = parseInt(len / max)
    }

    const arg = []
    for (let i = 0; i < total; i++) {
      const s = 0 + i * max
      let e = s + max

      if (i + 1 >= total) {
        e = base64.length
      }
      arg.push(base64.substring(s, e))
    }

    return arg
  }

  /**  判断是否是IE内核 */
  isIE () {
    const x = window.ActiveXObject || 'ActiveXObject' in window
    return x
  }

  /**  是否使用OCX */
  isUseOcx () {
    const isOcx = typeof SmartCAPKI.prototype.smartCAIE !== 'undefined'
    return this.isIE() && isOcx
  }

  /** 通用的IE回调方法方法 */
  exeIE (res) {
    const { options } = this
    if (options.async == false) {
      if (res.status == RESPONSE_STATUS.OK) {
        return res.result
      }
      // throw CommponResponse.FAIL(res.ErrorCode, res.ErrorMsg);
      throw res
    }
    if (res.status == RESPONSE_STATUS.OK) {
      return Promise.resolve(res.result)
    }
    return Promise.reject(res)
  }
  /** 解码base64 */
  // encodeBase64(val) {
  //   return $.base64.atob(val, true);
  // }

  /**
   * 请求方法
   * @param {*} methodName
   * @param {*} paramJson
   * @returns { Promise<data | {errorCode:number; errorMsg: string;status: number;result:any}> }
   * @description 该方法会在成功时直接返回数据，失败时返回一个统一的错误响应
   * @description 默认走post请求，不走jsonp了，ie浏览器由于接受数据存在一道问题，走的是get请求，但本质上还是jsonp，同步与异步返回的数据是不一样的
   */
  exeFn (methodName, paramJson) {
    const params = {
      method: methodName,
      param: paramJson
    }
    const {
      webApiAddr,
      webApiPort,
      webApiIndex,
      options
    } = this
    if (options.requestType == 'jsonp') {
      const argparam = this.createParams(params)
      const promise = new Promise((resolve, reject) => {
        setTimeout(function () {
          // 并发提交
          const subFn = function (data, _methodName) {
            jsonp({
              url: `${webApiAddr}:${webApiPort}${webApiIndex}`,
              data: data,
              callbackParameter: 'callback',
              callbackFn: _methodName + '_' + data.sn + '_' + data.sort,
              success: function (res) {
                if (res.Status == 1) {
                  resolve(res.Result)
                } else if (res.Status == 0) {
                  reject(CommponResponse.FAIL(res.ErrorCode, res.ErrorMsg))
                }
              },
              error: function () {
                reject(
                  CommponResponse.FAIL(8000, '请检查智慧CA证书助手是否开启')
                )
              }
            })
          }
          // 开始请求
          for (let i = 0; i < argparam.length - 1; i++) {
            subFn(argparam[i], methodName)
          }
          subFn(argparam[argparam.length - 1], methodName)
        }, 0)
      })
      return promise
    }
    // get请求本质上走的还是jsonp，需要特别注意下！！！
    // IE 默认走的还是get请求
    // if(this.isIE()) {
    //   // 并发请求
    //   const subFn = function (data, _methodName) {
    //     const res = request({
    //       url: `${webApiAddr}:${webApiPort}${webApiIndex}`,
    //       data: data,
    //       callbackParameter: "callback",
    //       callbackFn: _methodName + "_" + data.sn + "_" + data.sort,
    //       method: "GET",
    //       async: false,
    //     });
    //     if(res) {
    //       const {Status} = res;
    //       if(Status === 1 ) {
    //         return res.Result;
    //       } else if(Status === 0) {
    //         throw CommponResponse.FAIL(res.ErrorCode, res.ErrorMsg);
    //       }
    //     }
    //     throw CommponResponse.FAIL(8000, "请检查智慧CA证书助手是否开启");
    //   };
    //   const argparam = this.createParams(params);
    //   if(options.async == false) {
    //     //开始请求
    //     for (let i = 0; i < argparam.length - 1; i++) {
    //       subFn(argparam[i], methodName);
    //     }
    //     return subFn(argparam[argparam.length - 1], methodName);
    //   }
    //   const promise = new Promise((resolve, reject) => {
    //     setTimeout(function () {
    //       //并发提交
    //       const subFn = function (data, _methodName) {
    //         request({
    //           url: `${webApiAddr}:${webApiPort}${webApiIndex}`,
    //           data: data,
    //           method: "GET",
    //           async: true,
    //           callbackFn: _methodName + "_" + data.sn + "_" + data.sort,
    //           success: function (res) {
    //             if (res.Status == 1) {
    //               resolve(res.Result);
    //             } else if (res.Status == 0) {
    //               reject(CommponResponse.FAIL(res.ErrorCode, res.ErrorMsg));
    //             }
    //           },
    //           error: function () {
    //             reject(
    //               CommponResponse.FAIL(8000, "请检查智慧CA证书助手是否开启")
    //             );
    //           },
    //         });
    //       };
    //       //开始请求
    //       for (let i = 0; i < argparam.length - 1; i++) {
    //         subFn(argparam[i], methodName);
    //       }
    //       subFn(argparam[argparam.length - 1], methodName);
    //     }, 0);
    //   });
    //   return promise;
    // }
    if (options.async == false) {
      const res = request({
        url: `${webApiAddr}:${webApiPort}${webApiIndex}`,
        data: { ...params },
        async: false,
        method: 'POST'
      })
      if (res) {
        const { Status } = res
        if (Status === 1) {
          return res.Result
        } else if (Status === 0) {
          throw CommponResponse.FAIL(res.ErrorCode, res.ErrorMsg)
        }
      }
      throw CommponResponse.FAIL(8000, '请检查智慧CA证书助手是否开启')
    }
    return new Promise((resolve, reject) => {
      request({
        url: `${webApiAddr}:${webApiPort}${webApiIndex}`,
        data: { ...params },
        method: 'POST',
        async: true,
        success: function (res) {
          if (res.Status == 1) {
            resolve(res.Result)
          } else if (res.Status == 0) {
            reject(CommponResponse.FAIL(res.ErrorCode, res.ErrorMsg))
          }
        },
        error: function () {
          reject(
            CommponResponse.FAIL(8000, '请检查智慧CA证书助手是否开启')
          )
        }
      })
    })
  }

  /** 构造分割参数 */
  createParams (data) {
    const sJosn = JSON.stringify(data)
    const base64 = this.base64Encode(sJosn)
    // get请求需要分割请求参数
    const arg = this.cutParam(base64)
    // 重新组装参数
    const sn = this.createSN()
    // 分割请求参数
    const argparam = []
    for (let i = 0, len = arg.length; i < len; i++) {
      const data = {
        sn: sn,
        total: len,
        sort: i,
        part: arg[i]
      }
      argparam.push(data)
    }
    return argparam
  }

  // /** 获取本地当前监听的 webapi port */
  // getWebApiPort () {
  //   const _this = this
  //   if (this.portIndex >= this.netonexPorts.length) {
  //     this.portIndex = 0
  //     return Promise.resolve(this.netonexPorts[this.portIndex])
  //   }
  //   this.webApiPort = this.netonexPorts[this.portIndex]
  //   const { options } = this
  //   if (options.async == false) {
  //     try {
  //       this.exeFn('SOF_GetVersion')
  //       return this.webApiPort
  //     } catch {
  //       _this.portIndex++
  //       return _this.getWebApiPort()
  //     }
  //   }
  //   const res = this.exeFn('SOF_GetVersion')
  //   if (res instanceof Promise) {
  //     return res
  //       .then(function () {
  //         return _this.webApiPort
  //       })
  //       .catch(function () {
  //         _this.portIndex++
  //         return _this.getWebApiPort()
  //       })
  //   }
  // }

  /** 获取版本号 */
  getVersion () {
    return this.exeFn('SOF_GetVersion')
  }

  /**
   * 获取证书助手版本号
   * @param {*} regName
   * @param regName 证书助手在注册表中的键值： 智慧ca助手：{A2EF3821-6D8D-4DB3-8188-E18F6CEF9779}； 中证报价助手：{B11B984E-E586-48B8-A8EB-006A18FEED52}
   * @returns
   *  1）成功返回值
     {
        "Version" : "1.0.18.1018" //返回的话即代表助手没有安装；
      }
   */
  getAssistantVersion (regName) {
    const paramJson = {
      regName: regName
    }
    return this.exeFn('SOF_GetAssistantVersion', paramJson)
  }

  /**
   * 解析证书Base64
   * @param cert  				[string]可以为空。证书base64，如果为空，则会弹出证书选择框
   * @param keyUsage  			[int]   可以为0。 设置证书枚举过滤属性，默认枚举所有证书。32签名证书，16加密证书，32|16 枚举所有
   * @param cryptoInterface  		[int]   可以为0.  设置证书枚举过滤算法，默认枚举所有算法证书。1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
   *
   * return JSON格式
   1）成功返回值
     {
        "Content" : "MIIDLzCCAhegAwIBAgIIIBgFIjKXOIAwDQYJKoZIhvcNAQEFBQAwgcUxNjA0BgNVBAMMLeaxn+iLj+aZuuaFp+aVsOWtl+iupOivgeaciemZkOWFrOWPuFJTQeacuuaehDEtMCsGA1UECwwk5rGf6IuP5pm65oWn5pWw5a2X6K6k6K+B5pyJ6ZmQ5YWs5Y+4MS0wKwYDVQQKDCTmsZ/oi4/mmbrmhafmlbDlrZforqTor4HmnInpmZDlhazlj7gxDzANBgNVBAcMBuWNl+S6rDEPMA0GA1UECAwG5rGf6IuPMQswCQYDVQQGEwJDTjAeFw0xODA1MjIwMjI1NDBaFw0yMTA1MjIwMjI1NDBaMFwxDDAKBgNVBAMMAzIzMTEOMAwGA1UECwwFMjEzMTIxDzANBgNVBAoMBjIxMzEyMzEOMAwGA1UEBwwFMTIzMjExDjAMBgNVBAgMBTIxMzIxMQswCQYDVQQGEwJDTjCBnzANBgkqhkiG9w0BAQEFAAOBjQAwgYkCgYEAwgZytlEnGaQuM8jRzjX/q2Slhl9cxcPyKOGKDykQWKFIiGcjDxvV6bZaxeWdwI6U/85PyD305n88ZwXbJF2nFc10ny5VQ0ZcQVZ1f1vafEIHlGPY919aOsKxKS1RaY6uRSmQtXLX+ROnnVlOiQNIJ3uM8HThudRfNrjzKbZ6nQ0CAwEAAaMPMA0wCwYDVR0PBAQDAgbAMA0GCSqGSIb3DQEBBQUAA4IBAQAeRhTJPcoWe8pFluLk8JTPh6stbxOcD7P5gf9x1uAl7bccUSE2HAvuERZMq6fCbewKz2JZwKnwC4K6Wc3ZUX+jWUNgAQlFfZySArVv8cjGQBe8YsagaXDFHDdPrAQkzi9XEznQtSBR9ZHpkoRZTvXGv0yI8C4NTipjki71e97t4IZEOXrCkT1u3TkMHoPHRDq173Ug6GdvGar9zOUlqgz5evJ0zlhl0BvnHrs4ngqz6qjJlmGMkJHTaQ+ZqYPvmZtHR1txgfOE9Tk8oQsCjUF2LgNSGkrMjC9lpsOStcYsNPjAIWGGopcVvqo6k/y8Dv3qFVR2LyMECk7MP88w/drc",
        "Issuer" : "CN = 江苏智慧数字认证有限公司RSA机构,OU = 江苏智慧数字认证有限公司,O = 江苏智慧数字认证有限公司,L = 南京,ST = 江苏,C = CN",
        "NotAfterSystemTime" : "2021-5-22 2:25:40",
        "NotAfterTimestamp" : 1621621540,
        "NotBeforeSystemTime" : "2018-5-22 2:25:40",
        "NotBeforeTimestamp" : 1526927140,
        "SerialNumber" : "2018052232973880",
        "SignatureAlgorithm" : "sha1WithRSAEncryption",
        "Subject" : "CN = 231,OU = 21312,O = 213123,L = 12321,ST = 21321,C = CN",
        "ThumbprintMD5" : "DCF07944A2E7AA784A83301A45C9BE19",
        "ThumbprintSHA1" : "D5849F52DFADB4E0E482CDE309428024C1274CF5",
        "Version" : ""
    }
    * 2）失败返回值
        {
            "ErrorCode" : XXX,
            "ErrorMsg" : "xxx",
            "Result" : 0，
            "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
        }
    */
  getCertInfo (cert, finger, subjectContains, issuerContains, keyUsage, cryptoInterface, defaultSelect, noCertErr) {
    const paramJson = {
      cert: cert,
      finger: finger,
      subjectContains: subjectContains,
      issuerContains: issuerContains,
      keyUsage: keyUsage,
      cryptoInterface: cryptoInterface,
      defaultSelect: defaultSelect,
      noCertErr: noCertErr
    }

    if (this.isUseOcx()) {
      // 如果是IE内核
      const {
        smartCAIE
      } = this
      const resultJson = smartCAIE.getCertInfo(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_GetCertInfo', paramJson)
  }

  /**
    * 解析证书，并且校验证书PIN码（弹出证书PIN码校验框）
  * @param cert  				[string]可以为空。证书base64，如果为空，则会弹出证书选择框
  * @param finger				[string]可以为空。证书指纹
  * @param subjectContains		[string]可以为空。弹出的证书选择框中，只显示主题项中包含该内容的证书
  * @param issuerContains		[string]可以为空。证书颁发者包含的内容
  * @param keyUsage  			[int]   可以为0。 设置证书枚举过滤属性，默认枚举所有证书。32签名证书，16加密证书，32|16 枚举所有
  * @param cryptoInterface  		[int]   可以为0。 设置证书枚举过滤算法，默认枚举所有算法证书。1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
  * @param defaultSelect			[int]   可以为0。 设置是否默认选择唯一的符合条件证书； 0：代表弹出证书选择框，1：代表，当仅有一张证书的时候，默认选择第一张证书
  * @param noCertErr				[int]   可以为0。 若设置为1：则没有证书的时候，不再弹出空的证书选择框，而是返回错误码
  *
  * return JSON格式
    1）成功返回值
    {
      "Content" : "MIIDLzCCAhegAwIBAgIIIBgFIjKXOIAwDQYJKoZIhvcNAQEFBQAwgcUxNjA0BgNVBAMMLeaxn+iLj+aZuuaFp+aVsOWtl+iupOivgeaciemZkOWFrOWPuFJTQeacuuaehDEtMCsGA1UECwwk5rGf6IuP5pm65oWn5pWw5a2X6K6k6K+B5pyJ6ZmQ5YWs5Y+4MS0wKwYDVQQKDCTmsZ/oi4/mmbrmhafmlbDlrZforqTor4HmnInpmZDlhazlj7gxDzANBgNVBAcMBuWNl+S6rDEPMA0GA1UECAwG5rGf6IuPMQswCQYDVQQGEwJDTjAeFw0xODA1MjIwMjI1NDBaFw0yMTA1MjIwMjI1NDBaMFwxDDAKBgNVBAMMAzIzMTEOMAwGA1UECwwFMjEzMTIxDzANBgNVBAoMBjIxMzEyMzEOMAwGA1UEBwwFMTIzMjExDjAMBgNVBAgMBTIxMzIxMQswCQYDVQQGEwJDTjCBnzANBgkqhkiG9w0BAQEFAAOBjQAwgYkCgYEAwgZytlEnGaQuM8jRzjX/q2Slhl9cxcPyKOGKDykQWKFIiGcjDxvV6bZaxeWdwI6U/85PyD305n88ZwXbJF2nFc10ny5VQ0ZcQVZ1f1vafEIHlGPY919aOsKxKS1RaY6uRSmQtXLX+ROnnVlOiQNIJ3uM8HThudRfNrjzKbZ6nQ0CAwEAAaMPMA0wCwYDVR0PBAQDAgbAMA0GCSqGSIb3DQEBBQUAA4IBAQAeRhTJPcoWe8pFluLk8JTPh6stbxOcD7P5gf9x1uAl7bccUSE2HAvuERZMq6fCbewKz2JZwKnwC4K6Wc3ZUX+jWUNgAQlFfZySArVv8cjGQBe8YsagaXDFHDdPrAQkzi9XEznQtSBR9ZHpkoRZTvXGv0yI8C4NTipjki71e97t4IZEOXrCkT1u3TkMHoPHRDq173Ug6GdvGar9zOUlqgz5evJ0zlhl0BvnHrs4ngqz6qjJlmGMkJHTaQ+ZqYPvmZtHR1txgfOE9Tk8oQsCjUF2LgNSGkrMjC9lpsOStcYsNPjAIWGGopcVvqo6k/y8Dv3qFVR2LyMECk7MP88w/drc",
      "Issuer" : "CN = 江苏智慧数字认证有限公司RSA机构,OU = 江苏智慧数字认证有限公司,O = 江苏智慧数字认证有限公司,L = 南京,ST = 江苏,C = CN",
      "NotAfterSystemTime" : "2021-5-22 2:25:40",
      "NotAfterTimestamp" : 1621621540,
      "NotBeforeSystemTime" : "2018-5-22 2:25:40",
      "NotBeforeTimestamp" : 1526927140,
      "SerialNumber" : "2018052232973880",
      "SignatureAlgorithm" : "sha1WithRSAEncryption",
      "Subject" : "CN = 231,OU = 21312,O = 213123,L = 12321,ST = 21321,C = CN",
      "ThumbprintMD5" : "DCF07944A2E7AA784A83301A45C9BE19",
      "ThumbprintSHA1" : "D5849F52DFADB4E0E482CDE309428024C1274CF5",
      "Version" : ""
    }
  * 2）失败返回值
    {
        "ErrorCode" : XXX,
        "ErrorMsg" : "xxx",
        "Result" : 0，
        "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
    }
  */
  getCertInfoAndVerifyPIN (cert, finger, subjectContains, issuerContains, keyUsage, cryptoInterface, defaultSelect, noCertErr) {
    const paramJson = {
      cert: cert,
      finger: finger,
      subjectContains: subjectContains,
      issuerContains: issuerContains,
      keyUsage: keyUsage,
      cryptoInterface: cryptoInterface,
      defaultSelect: defaultSelect,
      noCertErr: noCertErr
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const {
        smartCAIE
      } = this
      const resultJson = smartCAIE.getCertInfoAndVerifyPIN(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_GetCertInfoAndVerifyPIN', paramJson)
  }

  /**
   * 解析证书扩展项
   * @param cert  				[string]证书base64
   * @param finger				[string]证书指纹 （与证书base64 二选一必须填一个，如果2个都填，则以base64为准）
   * @param oid           		[string]需要解析的扩展项Oid
   * @param rawHex        		[int]   0: 尝试转成成可打印字符，如转换不成功默认将内容的十六进制字符串返回;1：返回base64字符串
   * return JSON格式
      1）成功返回值
        {
          "value" : "OC14..............."
        }
   * 2）失败返回值
      {
          "ErrorCode" : XXX,
          "ErrorMsg" : "xxx",
          "Result" : 0，
          "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
      }
   */
  getExtensionString (cert, finger, oid, rawHex) {
    const paramJson = {
      cert: cert,
      finger: finger,
      oid: oid,
      rawHex: rawHex
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const {
        smartCAIE
      } = this
      const resultJson = smartCAIE.GetExtensionString(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_GetExtensionString', paramJson)
  }

  /**
    * 清除PIN码缓存
    * @param cert  				[string]证书base64
    * @param finger				[string]证书指纹 (cert/finger 必须传入一个）
    *
    * return JSON格式
       1）成功返回值
          {
              "ErrorCode" : 0,
              "ErrorMsg" : "",
              "Result" : 0,
              "Status" : 1
          }
    * 2）失败返回值
        {
            "ErrorCode" : XXX,
            "ErrorMsg" : "xxx",
            "Result" : 0，
            "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
        }
    */
  clearPINCache (cert, finger) {
    const paramJson = {
      cert: cert,
      finger: finger
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const {
        smartCAIE
      } = this
      const resultJson = smartCAIE.clearPINCache(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_ClearPINCache', paramJson)
  }

  /**
   * 查询证书是否缓存PIN码
   * @param cert  				[string]证书base64
   * @param finger				[string]证书指纹 (cert/finger 必须传入一个）
   * @param succFn 成功的回调函数，上层传入
   * @param failFn 失败的回调函数，上层传入
   *
   * return JSON格式
      1）成功返回值
        {
          "ErrorCode" : 0,
          "ErrorMsg" : "",
          "Result" :
                  {
                    "isCachePin":1; //1：缓存；0：未缓存
                      }
          "Status" : 1
        }
  * 2）失败返回值
          {
              "ErrorCode" : XXX,
              "ErrorMsg" : "xxx",
              "Result" : 0，
              "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
          }
 */
  isPINCache (cert, finger) {
    const paramJson = {
      cert: cert,
      finger: finger
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const {
        smartCAIE
      } = this
      const resultJson = smartCAIE.isPINCache(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_IsPINCache', paramJson)
  }

  /**
     * 弹出证书PIN码校验框
     * @param cert  				[string]证书base64
     * @param finger				[string]证书指纹 (cert/finger 必须传入一个）
     * @param succFn 成功的回调函数，上层传入
     * @param failFn 失败的回调函数，上层传入
     *
     * return JSON格式
        1）成功返回值
            {
                "ErrorCode" : 0,
                "ErrorMsg" : "",
                "Result" : 0,
                "Status" : 1
            }
     * 2）失败返回值
            {
                "ErrorCode" : XXX,
                "ErrorMsg" : "xxx",
                "Result" : 0，
                "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
            }
     */
  verifyPINDialog (cert, finger) {
    const paramJson = {
      cert: cert,
      finger: finger
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const {
        smartCAIE
      } = this
      const resultJson = smartCAIE.verifyPINDialog(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_VerifyPINDialog', paramJson)
  }

  /**
     * 获取pkcs1签名
     * @param data  				[string]	需要签名的原文数据
     * @param cert  	 			[string]	可以为空；签名证书base64，可以通过指定证书Base64或者指纹实例化证书对象，然后静默进行相应的签名或加解密等操作，如果cert和finger都为空，则会弹出证书选择框
     * @param finger 				[string]	可以为空；证书指纹字符串, 可以是SHA1的结果, 也可以是MD5的结果 ，不区分大小写。当cert和finger都存在时，以cert为主。
     * @param alg 					[string]	可以为空；签名算法（RSA证书默认是SHA1，SM2证书固定SM3），仅对RSA证书有效，用与解决部分CA证书仅支持sha256签名算法。需要key支持相应的签名算法
     * @param cryptoInterface  		[int]   	可以为0.  设置证书枚举过滤算法，默认枚举所有算法证书。1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
     * @param defaultSelect			[int]   可以为0。 设置是否默认选择唯一的符合条件证书； 0：代表弹出证书选择框，1：代表，当仅有一张证书的时候，默认选择第一张证书
     * @param noCertErr				[int]   可以为0。 若设置为1：则没有证书的时候，不再弹出空的证书选择框，而是返回错误码
     *
        return JSON格式

        1）成功返回值
            {

                "signData" : "MbG6f1SPQ1RNZf9d...",  //pkcs1签名值
                "cert":"MIIDLzCCAhegAwIBAgIIIBgFIjKX", //签名证书Base64
                "finger" : "B6A52718E1BB6716E9CA115D631C5F2FF69FFA79" //签名证书指纹
            }
        2）失败返回值
            {
                "ErrorCode" : XXX,
                "ErrorMsg" : "xxx",
                "Result" : 0，
                "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
            }
     */
  pkcs1String (
    data,
    cert,
    finger,
    alg,
    cryptoInterface,
    defaultSelect,
    noCertErr
  ) {
    const paramJson = {
      data: data,
      cert: cert,
      finger: finger,
      alg: alg,
      cryptoInterface: cryptoInterface,
      defaultSelect: defaultSelect,
      noCertErr: noCertErr
    }

    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.pkcs1String(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_Pkcs1String', paramJson)
  }

  /**
    * 获取摘要的pkcs1签名
    * @param data  				[string]	需要签名的摘要数据，必须是base64格式
    * @param cert  	 			[string]	可以为空；签名证书base64，可以通过指定证书Base64或者指纹实例化证书对象，然后静默进行相应的签名或加解密等操作，如果cert和finger都为空，则会弹出证书选择框
    * @param finger 				[string]	可以为空；证书指纹字符串, 可以是SHA1的结果, 也可以是MD5的结果 ，不区分大小写。当cert和finger都存在时，以cert为主。
    * @param alg 					[string]	必填；摘要算法：可填项为：sha1、sha256、sm3，（小写字母）
    * @param pwd					[string]	可以为空；证书pin码
    * @param cryptoInterface  		[int]   	可以为0.  设置证书枚举过滤算法，默认枚举所有算法证书。1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
    * @param defaultSelect			[int]   可以为0。 设置是否默认选择唯一的符合条件证书； 0：代表弹出证书选择框，1：代表，当仅有一张证书的时候，默认选择第一张证书
    * @param noCertErr				[int]   可以为0。 若设置为1：则没有证书的时候，不再弹出空的证书选择框，而是返回错误码
    *
       return JSON格式

       1）成功返回值
           {
                   "signData" : "MbG6f1SPQ1RNZf9d...",  //pkcs1签名值
                   "cert":"MIIDLzCCAhegAwIBAgIIIBgFIjKX", //签名证书Base64
                   "finger" : "B6A52718E1BB6716E9CA115D631C5F2FF69FFA79" //签名证书指纹
           }
       2）失败返回值
           {
               "ErrorCode" : XXX,
               "ErrorMsg" : "xxx",
               "Result" : 0，
               "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
           }
    */
  pkcs1Digest (
    data,
    cert,
    finger,
    alg,
    pwd,
    cryptoInterface,
    defaultSelect,
    noCertErr
  ) {
    const paramJson = {
      data: data,
      cert: cert,
      finger: finger,
      alg: alg,
      pwd: pwd,
      cryptoInterface: cryptoInterface,
      defaultSelect: defaultSelect,
      noCertErr: noCertErr
    }

    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.pkcs1Digest(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_Pkcs1Digest', paramJson)
  }

  /**
    * 获取Base64字符串的pkcs1签名，主要用于解决二进制数据的签名
    * @param data  				[string]	需要签名的摘要数据，必须是base64格式
    * @param cert  	 			[string]	可以为空；签名证书base64，可以通过指定证书Base64或者指纹实例化证书对象，然后静默进行相应的签名或加解密等操作，如果cert和finger都为空，则会弹出证书选择框
    * @param finger 				[string]	可以为空；证书指纹字符串, 可以是SHA1的结果, 也可以是MD5的结果 ，不区分大小写。当cert和finger都存在时，以cert为主。
    * @param alg 					[string]	可以为空；签名算法（RSA证书默认是SHA1，SM2证书固定SM3），仅对RSA证书有效，用与解决部分CA证书仅支持sha256签名算法。需要key支持相应的签名算法
    * @param pwd					[string]	可以为空；证书pin码
    * @param cryptoInterface  		[int]   	可以为0.  设置证书枚举过滤算法，默认枚举所有算法证书。1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
    * @param defaultSelect			[int]   可以为0。 设置是否默认选择唯一的符合条件证书； 0：代表弹出证书选择框，1：代表，当仅有一张证书的时候，默认选择第一张证书
    * @param noCertErr				[int]   可以为0。 若设置为1：则没有证书的时候，不再弹出空的证书选择框，而是返回错误码
    *
       return JSON格式

       1）成功返回值
           {
            "signData" : "MbG6f1SPQ1RNZf9d...",  //pkcs1签名值
            "cert":"MIIDLzCCAhegAwIBAgIIIBgFIjKX", //签名证书Base64
            "finger" : "B6A52718E1BB6716E9CA115D631C5F2FF69FFA79" //签名证书指纹
           }
       2）失败返回值
           {
               "ErrorCode" : XXX,
               "ErrorMsg" : "xxx",
               "Result" : 0，
               "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
           }
    */
  pkcs1Base64 (
    data,
    cert,
    finger,
    alg,
    pwd,
    cryptoInterface,
    defaultSelect,
    noCertErr
  ) {
    const paramJson = {
      data: data,
      cert: cert,
      finger: finger,
      alg: alg,
      pwd: pwd,
      cryptoInterface: cryptoInterface,
      defaultSelect: defaultSelect,
      noCertErr: noCertErr
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.pkcs1Base64(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_Pkcs1Base64', paramJson)
  }

  /**
	 * 获取Utf8字符串的pkcs1签名值，内部把传入的签名原数据当成utf8字符串去处理；
	 * @param data  				[string]	需要签名的摘要数据，必须是base64格式
	 * @param cert  	 			[string]	可以为空；签名证书base64，可以通过指定证书Base64或者指纹实例化证书对象，然后静默进行相应的签名或加解密等操作，如果cert和finger都为空，则会弹出证书选择框
	 * @param finger 				[string]	可以为空；证书指纹字符串, 可以是SHA1的结果, 也可以是MD5的结果 ，不区分大小写。当cert和finger都存在时，以cert为主。
	 * @param alg 					[string]	可以为空；签名算法（RSA证书默认是SHA1，SM2证书固定SM3），仅对RSA证书有效，用与解决部分CA证书仅支持sha256签名算法。需要key支持相应的签名算法
	 * @param pwd					[string]	可以为空；证书pin码
	 * @param cryptoInterface  		[int]   	可以为0.  设置证书枚举过滤算法，默认枚举所有算法证书。1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
	 * @param defaultSelect			[int]   可以为0。 设置是否默认选择唯一的符合条件证书； 0：代表弹出证书选择框，1：代表，当仅有一张证书的时候，默认选择第一张证书
	 * @param noCertErr				[int]   可以为0。 若设置为1：则没有证书的时候，不再弹出空的证书选择框，而是返回错误码
	 *
	    return JSON格式
		1）成功返回值
			{
        "signData" : "MbG6f1SPQ1RNZf9d...",  //pkcs1签名值
        "cert":"MIIDLzCCAhegAwIBAgIIIBgFIjKX", //签名证书Base64
        "finger" : "B6A52718E1BB6716E9CA115D631C5F2FF69FFA79" //签名证书指纹
			}
		2）失败返回值
			{
				"ErrorCode" : XXX,
				"ErrorMsg" : "xxx",
				"Result" : 0，
				"Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
			}
	 */
  pkcs1Utf8String (data, cert, finger, alg, pwd, cryptoInterface, defaultSelect, noCertErr) {
    const paramJson = {
      data: data,
      cert: cert,
      finger: finger,
      alg: alg,
      pwd: pwd,
      cryptoInterface: cryptoInterface,
      defaultSelect: defaultSelect,
      noCertErr: noCertErr
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.pkcs1Utf8String(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_Pkcs1Utf8String', paramJson)
  }

  /**
    * 静默获取pkcs1签名
    * @param data  				[string]	需要签名的原文数据
    * @param cert  	 			[string]	可以为空；签名证书base64，可以通过指定证书Base64或者指纹实例化证书对象，然后静默进行相应的签名或加解密等操作，如果cert和finger都为空，则会弹出证书选择框
    * @param finger 				[string]	可以为空；证书指纹字符串, 可以是SHA1的结果, 也可以是MD5的结果 ，不区分大小写。当cert和finger都存在时，以cert为主。
    * @param alg 					[string]	可以为空；签名算法（RSA证书默认是SHA1，SM2证书固定SM3），仅对RSA证书有效，用与解决部分CA证书仅支持sha256签名算法。需要key支持相应的签名算法
    * @param pwd					[string]    PIN码
    * @param cryptoInterface  		[int]   	可以为0.  设置证书枚举过滤算法，默认枚举所有算法证书。1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
    *
       return JSON格式

       1）成功返回值
           {
							"signData" : "MbG6f1SPQ1RNZf9d...",  //pkcs1签名值
							"cert":"MIIDLzCCAhegAwIBAgIIIBgFIjKX", //签名证书Base64
							"finger" : "B6A52718E1BB6716E9CA115D631C5F2FF69FFA79" //签名证书指纹
           }
       2）失败返回值
           {
               "ErrorCode" : XXX,
               "ErrorMsg" : "xxx",
               "Result" : 0，
               "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
           }
    */
  pkcs1Silent (data, cert, finger, alg, pwd, cryptoInterface) {
    const paramJson = {
      data: data,
      cert: cert,
      finger: finger,
      alg: alg,
      pwd: pwd,
      cryptoInterface: cryptoInterface
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.pkcs1Silent(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_Pkcs1Silent', paramJson)
  }

  /**
    * pkcs1签名验证
    * @param base64Data  		[string] 原文的Base64数据
    * @param cert  			[string] 可以为空，签名证书base64，如果为空，则会弹出证书选择框
    * @param signData 			[string] pkcs1签名数据
    * @param cryptoInterface  	[int]    可以为0.  设置证书枚举过滤算法，默认枚举所有算法证书。1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
    *
       return JSON格式

       1）成功返回值
       {
           "Result" : 0,
       }
       2）失败返回值
       {
           "ErrorCode" : 83886090,
           "ErrorMsg" : "PKCS#1 VERIFY FAILED: EVP_VerifyFinal(). (bad signature)",
           "Result" : 0,
           "Status" : 0
       }
    *
    */
  pkcs1StringVerify (base64Data, cert, signData, cryptoInterface) {
    const paramJson = {
      base64Data: base64Data,
      cert: cert,
      signData: signData,
      cryptoInterface: cryptoInterface
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.pkcs1StringVerify(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_Pkcs1StringVerify', paramJson)
  }

  /**
     * 获取pkcs7签名
     * @param data  			[string]	签名的原文数据
     * @param cert  	    	[string]	可以为空；签名证书base64，可以通过指定证书Base64或者指纹实例化证书对象，然后静默进行相应的签名或加解密等操作，如果cert和finger都为空，则会弹出证书选择框
     * @param finger 			[string]	可以为空；证书指纹字符串, 可以是SHA1的结果, 也可以是MD5的结果 ，不区分大小写。当cert和finger都存在时，以cert为主。
     * @param alg 				[string]	可以为空；签名算法（RSA证书默认是SHA1，SM2证书固定SM3），仅对RSA证书有效，用与解决部分CA证书仅支持sha256签名算法，需要key支持相应的签名算法。
     * @param detach 			[int] 		可以为空：默认是"0"：0-附加原文，1-不附加原文
     * @param cryptoInterface  	[int]  		可以为0.  设置证书枚举过滤算法，默认枚举所有算法证书。1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
     * @param defaultSelect			[int]   可以为0。 设置是否默认选择唯一的符合条件证书； 0：代表弹出证书选择框，1：代表，当仅有一张证书的时候，默认选择第一张证书
     * @param noCertErr				[int]   可以为0。 若设置为1：则没有证书的时候，不再弹出空的证书选择框，而是返回错误码

      return JSON格式
     1）成功返回值
            {
							"signData" : "MIIE8wYJKoZ...", //PKCS7签名值
							"cert":"MIIDLzCCAhegAwIBAgII...", //签名证书Base64
							"finger" : "B6A52718E1BB6716E9CA115D631C5F2FF69FFA79" //签名证书的SHA1指纹
            }
        2）失败返回值
            {
                "ErrorCode" : XXX,
                "ErrorMsg" : "xxx",
                "Result" : 0，
                "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
            }
     *
     */
  pkcs7String (
    data,
    cert,
    finger,
    alg,
    detach,
    cryptoInterface,
    defaultSelect,
    noCertErr
  ) {
    const paramJson = {
      data: data,
      cert: cert,
      finger: finger,
      detach: detach,
      alg: alg,
      cryptoInterface: cryptoInterface,
      defaultSelect: defaultSelect,
      noCertErr: noCertErr
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.pkcs7String(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_Pkcs7String', paramJson)
  }

  /**
        * 静默获取pkcs7签名
        * @param data  				[string]	需要签名的原文数据
        * @param cert  	 			[string]	可以为空；签名证书base64，可以通过指定证书Base64或者指纹实例化证书对象，然后静默进行相应的签名或加解密等操作，如果cert和finger都为空，则会弹出证书选择框
        * @param finger 				[string]	可以为空；证书指纹字符串, 可以是SHA1的结果, 也可以是MD5的结果 ，不区分大小写。当cert和finger都存在时，以cert为主。
        * @param alg 					[string]	可以为空；签名算法（RSA证书默认是SHA1，SM2证书固定SM3），仅对RSA证书有效，用与解决部分CA证书仅支持sha256签名算法。需要key支持相应的签名算法
        * @param pwd					[string]    PIN码
        * @param cryptoInterface  		[int]   	可以为0.  设置证书枚举过滤算法，默认枚举所有算法证书。1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
        * @param detach 			    [int] 		可以为空：默认是"0"：0-附加原文，1-不附加原文
        *
                return JSON格式

                1）成功返回值
                        {
                                "ErrorCode" : 0,
                                "ErrorMsg" : "",
                                "Result" : {
                                        "signData" : "MbG6f1SPQ1RNZf9d...",  //pkcs1签名值
                                        "cert":"MIIDLzCCAhegAwIBAgIIIBgFIjKX", //签名证书Base64
                                        "finger" : "B6A52718E1BB6716E9CA115D631C5F2FF69FFA79" //签名证书指纹
                                },
                                "Status" : 1  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
                        }
                2）失败返回值
                        {
                                "ErrorCode" : XXX,
                                "ErrorMsg" : "xxx",
                                "Result" : 0，
                                "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
                        }
        */
  pkcs7Silent (data, cert, finger, alg, pwd, detach, cryptoInterface) {
    const paramJson = {
      data: data,
      cert: cert,
      finger: finger,
      alg: alg,
      pwd: pwd,
      detach: detach,
      cryptoInterface: cryptoInterface
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.pkcs7Silent(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_Pkcs7Silent', paramJson)
  }

  /**
     * pkcs7签名验签
     * @param signData  	[string]	pkcs7签名数据
     * @param detach 		  [int]		  可以为0：默认是"0"：0-附加原文，1-不附加原文
     * @param deta 		    [string]  原文数据
      return JSON格式

        1）成功返回值
        {
            "Result" : 0,
        }
        2）失败返回值
        {
            "ErrorCode" : 83886090,
            "ErrorMsg" : "PKCS#7 VERIFY FAILED: EVP_VerifyFinal(). (bad signature)",
            "Result" : 0,
            "Status" : 0
        }
     *
     */
  pkcs7Verify (signData, detach, data) {
    const paramJson = {
      signData: signData,
      detach: detach,
      data: data
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.pkcs7Verify(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_Pkcs7Verify', paramJson)
  }

  /**
    * 获取pkcs7签名
    * @param data  			[string]	摘要的base64
    * @param cert  	    	[string]	可以为空；签名证书base64，可以通过指定证书Base64或者指纹实例化证书对象，然后静默进行相应的签名或加解密等操作，如果cert和finger都为空，则会弹出证书选择框
    * @param finger 			[string]	可以为空；证书指纹字符串, 可以是SHA1的结果, 也可以是MD5的结果 ，不区分大小写。当cert和finger都存在时，以cert为主。
    * @param alg 				[string]	可以为空；签名算法（RSA证书默认是SHA1，SM2证书固定SM3），仅对RSA证书有效，用与解决部分CA证书仅支持sha256签名算法，需要key支持相应的签名算法。
    * @param detach 			[int] 		可以为空：默认是"0"：0-附加原文，1-不附加原文
    * @param cryptoInterface  	[int]  		可以为0.  设置证书枚举过滤算法，默认枚举所有算法证书。1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
    * @param defaultSelect			[int]   可以为0。 设置是否默认选择唯一的符合条件证书； 0：代表弹出证书选择框，1：代表，当仅有一张证书的时候，默认选择第一张证书
    * @param noCertErr				[int]   可以为0。 若设置为1：则没有证书的时候，不再弹出空的证书选择框，而是返回错误码

     return JSON格式
    1）成功返回值
           {
               "ErrorCode" : 0,
               "ErrorMsg" : "",
               "Result" : {
                   "signData" : "MIIE8wYJKoZ...", //PKCS7签名值
                   "cert":"MIIDLzCCAhegAwIBAgII...", //签名证书Base64
                   "finger" : "B6A52718E1BB6716E9CA115D631C5F2FF69FFA79" //签名证书的SHA1指纹
                   },
               "Status" : 1  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
           }
       2）失败返回值
           {
               "ErrorCode" : XXX,
               "ErrorMsg" : "xxx",
               "Result" : 0，
               "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
           }
    *
    */

  pkcs7Digest (data, cert, finger, alg, detach, cryptoInterface, defaultSelect, noCertErr) {
    const paramJson = {
      data: data,
      cert: cert,
      finger: finger,
      detach: detach,
      alg: alg,
      cryptoInterface: cryptoInterface,
      defaultSelect: defaultSelect,
      noCertErr: noCertErr
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.pkcs7Digest(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_Pkcs7Digest', paramJson)
  }

  /**
      * 获取国密签章值
     * @param data  			[string]	摘要的base64
     * @param fileName        [string]    文件名称
     * @param cert  	    	[string]	可以为空；签名证书base64，可以通过指定证书Base64或者指纹实例化证书对象，然后静默进行相应的签名或加解密等操作，如果cert和finger都为空，则会弹出证书选择框
     * @param finger 			[string]	可以为空；证书指纹字符串, 可以是SHA1的结果, 也可以是MD5的结果 ，不区分大小写。当cert和finger都存在时，以cert为主。
     * @param alg 				[string]	可以为空；签名算法（RSA证书默认是SHA1，SM2证书固定SM3），仅对RSA证书有效，用与解决部分CA证书仅支持sha256签名算法，需要key支持相应的签名算法。
     * @param detach 			[int] 		可以为空：默认是"0"：0-附加原文，1-不附加原文
     * @param cryptoInterface  	[int]  		可以为0.  设置证书枚举过滤算法，默认枚举所有算法证书。1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
     * @param defaultSelect			[int]   可以为0。 设置是否默认选择唯一的符合条件证书； 0：代表弹出证书选择框，1：代表，当仅有一张证书的时候，默认选择第一张证书
     * @param noCertErr				[int]   可以为0。 若设置为1：则没有证书的时候，不再弹出空的证书选择框，而是返回错误码

                return JSON格式
     1）成功返回值
            {
                "ErrorCode" : 0,
                "ErrorMsg" : "",
                "Result" : {
                    "signData" : "MIIE8wYJKoZ...", //签名值
                    "cert":"MIIDLzCCAhegAwIBAgII...", //签名证书Base64
                    "finger" : "B6A52718E1BB6716E9CA115D631C5F2FF69FFA79" //签名证书的SHA1指纹
                    },
                "Status" : 1  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
            }
        2）失败返回值
            {
                "ErrorCode" : XXX,
                "ErrorMsg" : "xxx",
                "Result" : 0，
                "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
            }
        */
  getGMSealInfo (data, fileName, cert, finger, defaultSelect, noCertErr) {
    const paramJson = {
      data: data,
      fileName: fileName,
      cert: cert,
      finger: finger,
      defaultSelect: defaultSelect,
      noCertErr: noCertErr
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.getGMSealInfo(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_GetGMSealInfo', paramJson)
  }

  /**
            * 校验国密签章值
         * @param data  			[string]	摘要的base64
         * @param sealInfo          [string]    国密签章值的base64格式
              return JSON格式
         1）成功返回值
                {
                    "ErrorCode" : 0,
                    "ErrorMsg" : "",
                    "Result" : 0,
                    "Status" : 1  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
                }
            2）失败返回值
                {
                    "ErrorCode" : XXX,
                    "ErrorMsg" : "xxx",
                    "Result" : 0，
                    "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
                }
             *
             */
  verifyGMSealInfo (data, sealInfo) {
    const paramJson = {
      data: data,
      sealInfo: sealInfo
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.verifyGMSealInfo(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_VerifyGMSealInfo', paramJson)
  }

  /**
     *加密
     * @param data 		[string] 加密原文
     * @param cert  	[string] 可以为空；加密证书base64，可以通过指定证书Base64或者指纹实例化证书对象，然后静默进行相应的签名或加解密等操作，如果cert和finger都为空，则会弹出证书选择框
     * @param finger 	[string] 可以为空；证书指纹字符串, 可以是SHA1的结果, 也可以是MD5的结果 ，不区分大小写。当cert和finger都存在时，以cert为主。
     * @param alg 		[string] 可以为空；数字信封中使用的对称算法，默认是：sm4-ecb，可选项为：sm4-ecb, sm4-cbc, aes-128-ecb, aes-128-cbc,
     * @param cryptoInterface  	[int]  可以为0.  设置证书枚举过滤算法，默认枚举所有算法证书。1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
      return JSON格式
        1）成功返回值：envData-加密的密文
        {
         "ErrorCode" : 0,
         "ErrorMsg" : "",
         "Result" : {
                "cert" : "MIIDLzCCAhegAwIBAgIIIB...",  //加密证书Base64
                "envData" : "MIIBxgYJKoZIhvcNAQ...",   //加密的密文
                "finger" : "B6A52718E1BB6716E9CA115D631C5F2FF69FFA79"  //加密证书的SHA1指纹
                },
        "Status" : 1
        }
        2）失败返回值
        {
                "ErrorCode" : XXX,
                "ErrorMsg" : "xxx",
                "Result" : 0，
                "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
        }
     */
  envSealString (data, cert, finger, alg, cryptoInterface) {
    const paramJson = {
      data: data,
      cert: cert,
      finger: finger,
      alg: alg,
      cryptoInterface: cryptoInterface
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.envSealString(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_EnvSealString', paramJson)
  }

  /**
		*解密
		* @param data 		[string]    需要解密的密文
		* @param cert  	[srring]	可以为空；证书base64，可以通过指定证书Base64或者指纹实例化证书对象，然后静默进行相应的签名或加解密等操作，如果cert和finger都为空，则会弹出证书选择框
		* @param finger	[string]	可以为空；证书指纹字符串, 可以是SHA1的结果, 也可以是MD5的结果 ，不区分大小写。当cert和finger都存在时，以cert为主。
		* @param cryptoInterface  	[int]  可以为0.  设置证书枚举过滤算法，默认枚举所有算法证书。1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
			return JSON格式
				1）成功返回值：envData-原文的明文，base64EnvData-原文的base64，cert-加密证书base64
						{
						"ErrorCode" : 0,
						"ErrorMsg" : "",
						"Result" : {
								"base64EnvData" : "MTIz",  //加密原文的Base64格式
								"cert" : "MIIDLzCCAhegAwIBAgIIIBgFIjKXOIAwDQYJK....", //加密证书Base64
								"envData" : "123",		//加密的原文
								"finger" : "B6A52718E1BB6716E9CA115D631C5F2FF69FFA79" //加密证书SHA1指纹
								},
						"Status" : 1
						}
				2）失败返回值
						{
								"ErrorCode" : XXX,
								"ErrorMsg" : "xxx",
								"Result" : 0，
								"Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
						}
		*
		*/
  envOpenString (data, cert, finger, cryptoInterface) {
    const paramJson = {
      data: data,
      cert: cert,
      finger: finger,
      cryptoInterface: cryptoInterface
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.envOpenString(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_EnvOpenString', paramJson)
  }

  /**
	 * Base64 编码
	 * @param data 		需要编码的原文
	  return JSON格式
		1）成功返回值：
			{
			"ErrorCode" : 0,
			"ErrorMsg" : "",
			"Result" : {
				"base64Data":"Mtxzzdfsaf"
				},
			"Status" : 1
			}
		2）失败返回值
			{
				"ErrorCode" : XXX,
				"ErrorMsg" : "xxx",
				"Result" : 0，
				"Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
			}
	 *
	 */
  base64EncodeString (data) {
    const paramJson = {
      data: data
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.base64EncodeString(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_Base64EncodeString', paramJson)
  }

  /**
       * Base64 解码
       * @param data 		需要解码的Base64
        return JSON格式
        1）成功返回值：
          {
          "ErrorCode" : 0,
          "ErrorMsg" : "",
          "Result" : {
            "data":"1231"
            },
          "Status" : 1
          }
        2）失败返回值
          {
            "ErrorCode" : XXX,
            "ErrorMsg" : "xxx",
            "Result" : 0，
            "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
          }
       *
       */
  base64DecodeString (data) {
    const paramJson = {
      data: data
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.base64DecodeString(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_Base64DecodeString', paramJson)
  }

  /**
       * Base64 Utf8编码: 把字符串转换成Utf-8格式，然后再做Base64编码
       * @param data 		需要编码的原文
       * @param succFn 	成功的回调函数，上层传入
       * @param failFn 	失败的回调函数，上层传入
        return JSON格式
        1）成功返回值：
          {
          "ErrorCode" : 0,
          "ErrorMsg" : "",
          "Result" : {
            "base64Data":"Mtxzzdfsaf"
            },
          "Status" : 1
          }
        2）失败返回值
          {
            "ErrorCode" : XXX,
            "ErrorMsg" : "xxx",
            "Result" : 0，
            "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
          }
       *
       */
  base64EncodeUtf8 (data) {
    const paramJson = {
      data: data
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.base64EncodeUtf8(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_Base64EncodeUtf8String', paramJson)
  }

  /**
    * Base64 解码原文是Utf-8的Base64字符串，解码成功以后，把Utf8的字符串转换成系统默认格式字符串，然后返回。
    * @param data 		需要解码的Base64
    * @param succFn 	成功的回调函数，上层传入
    * @param failFn 	失败的回调函数，上层传入
    return JSON格式
    1）成功返回值：
      {
      "ErrorCode" : 0,
      "ErrorMsg" : "",
      "Result" : {
        "data":"1231"
        },
      "Status" : 1
      }
    2）失败返回值
      {
        "ErrorCode" : XXX,
        "ErrorMsg" : "xxx",
        "Result" : 0，
        "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
      }
    *
    */
  base64DecodeUtf8 (data) {
    const paramJson = {
      data: data
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.base64DecodeUtf8(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_Base64DecodeUtf8String', paramJson)
  }

  /**
	 * 小文件加密 (建议文件Size < 10M)
	 * @param srcFile 			[string] 需要加密的文件本地路径
	 * @param dstFile			[string] 加密后的文件保存的本地路径
	 * @param cert  			[string] 可以为空；加密证书base64，可以通过指定证书Base64或者指纹实例化证书对象，然后静默进行相应的签名或加解密等操作，如果cert和finger都为空，则会弹出证书选择框
	 * @param finger 			[string] 可以为空；证书指纹字符串, 可以是SHA1的结果, 也可以是MD5的结果 ，不区分大小写。当cert和finger都存在时，以cert为主。
	 * @param alg 				[string] 可以为空；数字信封中使用的对称算法，默认是：sm4-cbc，可选项为：sm4-ecb, sm4-cbc, aes-128-ecb, aes-128-cbc,
	 * @param cryptoInterface  	[int]  可以为0.  设置证书枚举过滤算法，默认枚举所有算法证书。1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
	  return JSON格式
		1）成功返回值：
		{
		 "ErrorCode" : 0,
		 "ErrorMsg" : "",
         "Result" : 0,
		 "Status" : 1
		}
		2）失败返回值
		{
				"ErrorCode" : XXX,
				"ErrorMsg" : "xxx",
				"Result" : 0,
				"Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
		}
	 */
  envSealFile (srcFile, dstFile, cert, finger, alg, cryptoInterface) {
    const paramJson = {
      srcFile: srcFile,
      dstFile: dstFile,
      cert: cert,
      finger: finger,
      alg: alg,
      cryptoInterface: cryptoInterface
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.envSealFile(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_EnvSealFile', paramJson)
  }

  /**
    * 小文件解密 (建议文件Size < 10M)
    * @param srcFile 			[string] 需要解密的文件本地路径
    * @param dstFile			[string] 解密后的文件保存的本地路径
    * @param cert  			[string] 可以为空；加密证书base64，可以通过指定证书Base64或者指纹实例化证书对象，然后静默进行相应的签名或加解密等操作，如果cert和finger都为空，则会弹出证书选择框
    * @param finger 			[string] 可以为空；证书指纹字符串, 可以是SHA1的结果, 也可以是MD5的结果 ，不区分大小写。当cert和finger都存在时，以cert为主。
    * @param alg 				[string] 可以为空；数字信封中使用的对称算法，默认是：sm4-cbc，可选项为：sm4-ecb, sm4-cbc, aes-128-ecb, aes-128-cbc,
    * @param cryptoInterface  	[int]  可以为0.  设置证书枚举过滤算法，默认枚举所有算法证书。1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
    return JSON格式
    1）成功返回值：
    {
      "ErrorCode" : 0,
      "ErrorMsg" : "",
          "Result" : 0,
    "Status" : 1
    }
    2）失败返回值
    {
        "ErrorCode" : XXX,
        "ErrorMsg" : "xxx",
        "Result" : 0，
        "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
    }
    */
  envOpenFile (srcFile, dstFile, cert, finger, alg, cryptoInterface) {
    const paramJson = {
      srcFile: srcFile,
      dstFile: dstFile,
      cert: cert,
      finger: finger,
      alg: alg,
      cryptoInterface: cryptoInterface
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.envOpenFile(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_EnvOpenFile', paramJson)
  }

  /**
    * 大文件加密
    * @param srcFile 			[string] 需要加密的文件本地路径
    * @param dstFile			[string] 加密后的文件保存的本地路径
    * @param cert  			[string] 可以为空；加密证书base64，可以通过指定证书Base64或者指纹实例化证书对象，然后静默进行相应的签名或加解密等操作，如果cert和finger都为空，则会弹出证书选择框
    * @param finger 			[string] 可以为空；证书指纹字符串, 可以是SHA1的结果, 也可以是MD5的结果 ，不区分大小写。当cert和finger都存在时，以cert为主。
    * @param alg 				[string] 可以为空；数字信封中使用的对称算法，默认是：sm4-cbc，可选项为：sm4-ecb, sm4-cbc, aes-128-ecb, aes-128-cbc,
    * @param cryptoInterface  	[int]  可以为0.  设置证书枚举过滤算法，默认枚举所有算法证书。1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
    return JSON格式
    1）成功返回值：
    {
      "ErrorCode" : 0,
      "ErrorMsg" : "",
          "Result" : 0,
      "Status" : 1
    }
    2）失败返回值
    {
        "ErrorCode" : XXX,
        "ErrorMsg" : "xxx",
        "Result" : 0,
        "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
    }
    */
  extSealFile (srcFile, dstFile, cert, finger, alg, cryptoInterface) {
    const paramJson = {
      srcFile: srcFile,
      dstFile: dstFile,
      cert: cert,
      finger: finger,
      alg: alg,
      cryptoInterface: cryptoInterface
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.extSealFile(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_ExtSealFile', paramJson)
  }

  /**
     * 大文件解密
     * @param srcFile 			[string] 需要解密的文件本地路径
     * @param dstFile			[string] 解密后的文件保存的本地路径
     * @param cert  			[string] 可以为空；加密证书base64，可以通过指定证书Base64或者指纹实例化证书对象，然后静默进行相应的签名或加解密等操作，如果cert和finger都为空，则会弹出证书选择框
     * @param finger 			[string] 可以为空；证书指纹字符串, 可以是SHA1的结果, 也可以是MD5的结果 ，不区分大小写。当cert和finger都存在时，以cert为主。
     * @param alg 				[string] 可以为空；数字信封中使用的对称算法，默认是：sm4-cbc，可选项为：sm4-ecb, sm4-cbc, aes-128-ecb, aes-128-cbc,
     * @param cryptoInterface  	[int]  可以为0.  设置证书枚举过滤算法，默认枚举所有算法证书。1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
      return JSON格式
      1）成功返回值：
      {
       "ErrorCode" : 0,
       "ErrorMsg" : "",
           "Result" : 0,
      "Status" : 1
      }
      2）失败返回值
      {
          "ErrorCode" : XXX,
          "ErrorMsg" : "xxx",
          "Result" : 0，
          "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
      }
     */
  extOpenFile (srcFile, dstFile, cert, finger, alg, cryptoInterface) {
    const paramJson = {
      srcFile: srcFile,
      dstFile: dstFile,
      cert: cert,
      finger: finger,
      alg: alg,
      cryptoInterface: cryptoInterface
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.extOpenFile(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_ExtOpenFile', paramJson)
  }

  /**
	 * 对文件签名(PKCS1)
	 * @param srcFile  				[string]	需要签名的文件本地路径
	 * @param cert  	 			[string]	可以为空；签名证书base64，可以通过指定证书Base64或者指纹实例化证书对象，然后静默进行相应的签名或加解密等操作，如果cert和finger都为空，则会弹出证书选择框
	 * @param finger 				[string]	可以为空；证书指纹字符串, 可以是SHA1的结果, 也可以是MD5的结果 ，不区分大小写。当cert和finger都存在时，以cert为主。
	 * @param alg 					[string]	可以为空；签名算法（RSA证书默认是SHA1，SM2证书固定SM3），仅对RSA证书有效，用与解决部分CA证书仅支持sha256签名算法。需要key支持相应的签名算法
	 * @param cryptoInterface  		[int]   	可以为0.  设置证书枚举过滤算法，默认枚举所有算法证书。1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
	 *
	  return JSON格式

		1）成功返回值
			{
				"ErrorCode" : 0,
				"ErrorMsg" : "",
				"Result" : {
					"signData" : "MbG6f1SPQ1RNZf9dX....",  //文件签名值
					"cert":"MIIDLzCCAh....", //签名证书的Base64
					"finger" : "B6A52718E1BB6716E9CA115D631C5F2FF69FFA79" //签名证书的SHA1指纹
				},
				"Status" : 1  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
			}
		2）失败返回值
			{
				"ErrorCode" : XXX,
				"ErrorMsg" : "xxx",
				"Result" : 0，
				"Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
			}
	 */
  pkcs1File (srcFile, cert, finger, alg, cryptoInterface) {
    const paramJson = {
      srcFile: srcFile,
      cert: cert,
      finger: finger,
      alg: alg,
      cryptoInterface: cryptoInterface
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.pkcs1File(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_Pkcs1File', paramJson)
  }

  /**
    * 文件签名验证
    * @param srcFile  			[string] 需要验证签名的文件本地路径
    * @param cert  			[string] 可以为空，签名证书base64，如果为空，则会弹出证书选择框
    * @param signData 			[string] 文件的签名值
    * @param cryptoInterface  	[int]  可以为0.  设置证书枚举过滤算法，默认枚举所有算法证书。1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
    *
      return JSON格式
      1）成功返回值
      {
        "ErrorCode" : 0,
        "ErrorMsg" : "",
        "Result" : 0,
        "Status" : 1
      }
      2）失败返回值
      {
        "ErrorCode" : 83886090,
        "ErrorMsg" : "PKCS#1 VERIFY FAILED: EVP_VerifyFinal(). (bad signature)",
        "Result" : 0,
        "Status" : 0
      }
    *
    */
  pkcs1FileVerify (srcFile, cert, signData, cryptoInterface) {
    const paramJson = {
      srcFile: srcFile,
      cert: cert,
      signData: signData,
      cryptoInterface: cryptoInterface
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.pkcs1FileVerify(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_Pkcs1FileVerify', paramJson)
  }

  /**
    * 文件hash值计算
    * @param srcFile  			[string] 需要计算hash值文件本地路径
    * @param alg				[string] hash算法，默认：sha256，可选的是：sha1，sha256，sm3
    *
        return JSON格式

      1）成功返回值
      {
        "ErrorCode" : 0,
        "ErrorMsg" : "",
        "Result" : 0,
        "Status" : 1
      }
      2）失败返回值
      {
        "ErrorCode" : XXX,
        "ErrorMsg" : "XXX",
        "Result" : 0,
        "Status" : 0
      }
    *
    */
  fileHash (srcFile, alg) {
    const paramJson = {
      srcFile: srcFile,
      alg: alg
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.fileHash(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_HashFile', paramJson)
  }

  /**
    * 字符串hash值计算
    * @param data  			[string] 需要计算hash值文件本地路径
    * @param alg				[string] hash算法，默认：sha256，可选的是：sha1，sha256，sm3
    *
      return JSON格式

    1）成功返回值
    {
      "ErrorCode" : 0,
      "ErrorMsg" : "",
      "Result" : 0,
      "Status" : 1
    }
    2）失败返回值
    {
      "ErrorCode" : XXX,
      "ErrorMsg" : "XXX",
      "Result" : 0,
      "Status" : 0
    }
    *
    */
  stringHash (data, alg) {
    const paramJson = {
      data: data,
      alg: alg
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.stringHash(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_HashString', paramJson)
  }

  /**
    * 对称加密文件
    * @param srcFile 			[string] 需要加密的文件本地路径
    * @param dstFile			[string] 加密后的文件保存的本地路径
    * @param key  				[string] 16对称密钥
    * @param iv	 			[string] 可以为空；16位初始向量，默认为16个0
    * @param alg				[string] 可以为空;默认是：sm4-cbc，可选项为：sm4-ecb, sm4-cbc, aes-128-ecb, aes-128-cbc,
    return JSON格式
    1）成功返回值：
    {
      "ErrorCode" : 0,
      "ErrorMsg" : "",
          "Result" : 0,
      "Status" : 1
    }
    2）失败返回值
    {
        "ErrorCode" : XXX,
        "ErrorMsg" : "xxx",
        "Result" : 0,
        "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
    }
  */
  encryptFile (srcFile, dstFile, key, iv, alg) {
    const paramJson = {
      srcFile: srcFile,
      dstFile: dstFile,
      alg: alg,
      key: key,
      iv: iv
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.encryptFile(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_EncryptFile', paramJson)
  }

  /**
    * 对称解密文件
    * @param srcFile 			[string] 需要解密的文件本地路径
    * @param dstFile			[string] 解密后的文件保存的本地路径
    * @param key  				[string] 16对称密钥
    * @param iv	 			[string] 可以为空；16位初始向量，默认为16个0
    * @param alg				[string] 可以为空;默认是：sm4-cbc，可选项为：sm4-ecb, sm4-cbc, aes-128-ecb, aes-128-cbc,
      return JSON格式
      1）成功返回值：
      {
        "ErrorCode" : 0,
        "ErrorMsg" : "",
            "Result" : 0,
        "Status" : 1
      }
      2）失败返回值
      {
          "ErrorCode" : XXX,
          "ErrorMsg" : "xxx",
          "Result" : 0,
          "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
      }
    */
  decryptFile (srcFile, dstFile, key, iv, alg) {
    const paramJson = {
      srcFile: srcFile,
      dstFile: dstFile,
      alg: alg,
      key: key,
      iv: iv
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.decryptFile(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_DecryptFile', paramJson)
  }

  /**
    * 对称加密文件（支持字节流形式的key和iv，以base64形式或者hexstr的方式传入字节流的key和iv ）
    * @param srcFile 			[string] 需要加密的文件本地路径
    * @param dstFile			[string] 加密后的文件保存的本地路径
    * @param key  				[string] 16对称密钥
    * @param iv	 		    	[string] 可以为空；16位初始向量，默认为16个0
    * @param alg				[string] 可以为空;默认是：sm4-cbc，可选项为：sm4-ecb, sm4-cbc, aes-128-ecb, aes-128-cbc,
    * @paran keyType            [int]    key和Iv的格式类型，1：base64格式；2：十六进制格式字符串；3：普通字符串
    * @param succFn 			成功的回调函数，上层传入
    * @param failFn 			失败的回调函数，上层传入
     return JSON格式
       1）成功返回值：
       {
        "ErrorCode" : 0,
        "ErrorMsg" : "",
        "Result" : 0,
        "Status" : 1
       }
       2）失败返回值
       {
               "ErrorCode" : XXX,
               "ErrorMsg" : "xxx",
               "Result" : 0,
               "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
       }
    */
  encryptFileEx (srcFile, dstFile, key, iv, alg, keyType) {
    const paramJson = {
      srcFile: srcFile,
      dstFile: dstFile,
      alg: alg,
      key: key,
      iv: iv,
      keyType: keyType
    }

    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.encryptFileEx(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_EncryptFileEx', paramJson)
  }

  /**
    * 对称解密文件 （支持字节流形式的key和iv，以base64形式或者hexstr的方式传入字节流的key和iv ）
    * @param srcFile 			[string] 需要解密的文件本地路径
    * @param dstFile			[string] 解密后的文件保存的本地路径
    * @param key  				[string] 16对称密钥
    * @param iv	 			    [string] 可以为空；16位初始向量，默认为16个0
    * @param alg				[string] 可以为空;默认是：sm4-cbc，可选项为：sm4-ecb, sm4-cbc, aes-128-ecb, aes-128-cbc,
    * @paran keyType            [int]    key和Iv的格式类型，1：base64格式；2：十六进制格式字符串；3：普通字符串
    * @param succFn 			成功的回调函数，上层传入
    * @param failFn 			失败的回调函数，上层传入
     return JSON格式
       1）成功返回值：
       {
        "ErrorCode" : 0,
        "ErrorMsg" : "",
        "Result" : 0,
        "Status" : 1
       }
       2）失败返回值
       {
               "ErrorCode" : XXX,
               "ErrorMsg" : "xxx",
               "Result" : 0,
               "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
       }
    */
  decryptFileEx (srcFile, dstFile, key, iv, alg, keyType) {
    const paramJson = {
      srcFile: srcFile,
      dstFile: dstFile,
      alg: alg,
      key: key,
      iv: iv,
      keyType: keyType
    }

    if (this.isIE()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.decryptFileEx(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_DecryptFileEx', paramJson)
  }

  /**
       * 字符串对称加密
       * @param data	 			[string] 需要加密的字符串
       * @param key  				[string] 16对称密钥
       * @param iv	 			[string] 可以为空；16位初始向量，默认为16个0
       * @param alg				[string] 可以为空;默认是：sm4-cbc，可选项为：sm4-ecb, sm4-cbc, aes-128-ecb, aes-128-cbc,
        return JSON格式
        1）成功返回值：
        {
         "ErrorCode" : 0,
         "ErrorMsg" : "",
             "Result" : 0,
         "Status" : 1
        }
        2）失败返回值
        {
            "ErrorCode" : XXX,
            "ErrorMsg" : "xxx",
            "Result" : 0,
            "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
        }
       */
  encryptString (data, key, iv, alg) {
    const paramJson = {
      data: data,
      alg: alg,
      key: key,
      iv: iv
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.encryptString(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_EncryptString', paramJson)
  }

  /**
       * 字符串对称解密
       * @param data	 			[string] 需要解密的字符串
       * @param key  				[string] 16对称密钥
       * @param iv	 			[string] 可以为空；16位初始向量，默认为16个0
       * @param alg				[string] 可以为空;默认是：sm4-cbc，可选项为：sm4-ecb, sm4-cbc, aes-128-ecb, aes-128-cbc,
        return JSON格式
        1）成功返回值：
        {
         "ErrorCode" : 0,
         "ErrorMsg" : "",
             "Result" : 0,
         "Status" : 1
        }
        2）失败返回值
        {
            "ErrorCode" : XXX,
            "ErrorMsg" : "xxx",
            "Result" : 0,
            "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
        }
       */
  decryptString (data, key, iv, alg) {
    const paramJson = {
      data: data,
      alg: alg,
      key: key,
      iv: iv
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.decryptString(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_DecryptString', paramJson)
  }

  /**
       * 计算随机数
       * @param length	 		[int] 随机数长度
        return JSON格式
        1）成功返回值：
        {
         "ErrorCode" : 0,
         "ErrorMsg" : "",
             "Result" : 0,
         "Status" : 1
        }
        2）失败返回值
        {
            "ErrorCode" : XXX,
            "ErrorMsg" : "xxx",
            "Result" : 0,
            "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
        }
       */
  random (length) {
    const paramJson = {
      length: length
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.random(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_Random', paramJson)
  }

  /**
       * 从电子印章管理平台获取签章图片
       * @param url  					[string]	电子印章管理平台服务地址
       * @param cert  	 			[string]	可以为空；签名证书base64，可以通过指定证书Base64或者指纹实例化证书对象，然后静默进行相应的签名或加解密等操作，如果cert和finger都为空，则会弹出证书选择框
       * @param finger 				[string]	可以为空；证书指纹字符串, 可以是SHA1的结果, 也可以是MD5的结果 ，不区分大小写。当cert和finger都存在时，以cert为主。
       * @param cryptoInterface  		[int]   	可以为0.  设置证书枚举过滤算法，默认枚举所有算法证书。1：只枚举SM2证书； 2：只枚举RSA证书；3：枚举所有证书
       * @param defaultSelect			[int]   可以为0。 设置是否默认选择唯一的符合条件证书； 0：代表弹出证书选择框，1：代表，当仅有一张证书的时候，默认选择第一张证书
       * @param noCertErr				[int]   可以为0。 若设置为1：则没有证书的时候，不再弹出空的证书选择框，而是返回错误码
       *
          return JSON格式

        1）成功返回值
          {
            "ErrorCode" : 0,
            "ErrorMsg" : "",
            "Result" : {
              "base64Image" : "MbG6f1SPQ1RNZf9d...",  //签章图片base64
              "cert":"MIIDLzCCAhegAwIBAgIIIBgFIjKX", //签名证书Base64
              "finger" : "B6A52718E1BB6716E9CA115D631C5F2FF69FFA79" //签名证书指纹
            },
            "Status" : 1  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
          }
        2）失败返回值
          {
            "ErrorCode" : XXX,
            "ErrorMsg" : "xxx",
            "Result" : 0，
            "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
          }
       */
  getStampFromSIMP (
    url,
    cert,
    finger,
    cryptoInterface,
    defaultSelect,
    noCertErr
  ) {
    const paramJson = {
      url: url,
      cert: cert,
      finger: finger,
      cryptoInterface: cryptoInterface,
      defaultSelect: defaultSelect,
      noCertErr: noCertErr
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.getStampFromSIMP(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_GetStampFromSIMP', paramJson)
  }

  /**
       * 获取印章中的图片信息
       * @param fileName  			[string]	印章文件名
       * @param cert  	 			[string]	可以为空；签名证书base64，可以通过指定证书Base64或者指纹实例化证书对象，然后静默进行相应的签名或加解密等操作，如果cert和finger都为空，则会弹出证书选择框
       * @param finger 				[string]	可以为空；证书指纹字符串, 可以是SHA1的结果, 也可以是MD5的结果 ，不区分大小写。当cert和finger都存在时，以cert为主。
       * @param defaultSelect			[int]   可以为0。 设置是否默认选择唯一的符合条件证书； 0：代表弹出证书选择框，1：代表，当仅有一张证书的时候，默认选择第一张证书
       * @param noCertErr				[int]   可以为0。 若设置为1：则没有证书的时候，不再弹出空的证书选择框，而是返回错误码
       *
          return JSON格式

        1）成功返回值
          {
            "ErrorCode" : 0,
            "ErrorMsg" : "",
            "Result" : {
              "sealImg" : "iVBORw0KGgoAAAANSUhEUg...",  //成功返回签章图片内容的base64编码
              "cert":"MIIDLzCCAhegAwIBAgIIIBgFIjKX", //签名证书Base64
              "finger" : "B6A52718E1BB6716E9CA115D631C5F2FF69FFA79" //签名证书指纹
            },
            "Status" : 1  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
          }
        2）失败返回值
          {
            "ErrorCode" : XXX,
            "ErrorMsg" : "xxx",
            "Result" : 0，
            "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
          }
       */
  getSIMPPicture (fileName, cert, finger, defaultSelect, noCertErr) {
    const paramJson = {
      fileName: fileName,
      cert: cert,
      finger: finger,
      defaultSelect: defaultSelect,
      noCertErr: noCertErr
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.GetSIMPPicture(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_GetSIMPPicture', paramJson)
  }

  /**
       * 打开文件对话框
       * @param title	 		[string] 可以为空，对话框标题;默认为“打开文件”
       * @param filterDesc 	[string] 文件类型过滤类型描述字符串，如：文本文件(*.txt|*.pdf|*.docx)
       * @param filterSpec 	[string] 文件类型过滤，多个类型用半角分号隔开，如：*.txt;*.pdf;*.docx
        return JSON格式
        1）成功返回值：
        {
         "ErrorCode" : 0,
         "ErrorMsg" : "",
             "Result" :
         {
           "filePath":"xxxxx"
         }
         "Status" : 1
        }
        2）失败返回值
        {
            "ErrorCode" : XXX,
            "ErrorMsg" : "xxx",
            "Result" : 0,
            "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
        }
       */
  openFileDialog (title, filterDesc, filterSpec) {
    const paramJson = {
      title: title,
      filterDesc: filterDesc,
      filterSpec: filterSpec
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.openFileDialog(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_OpenFileDialog', paramJson)
  }

  /**
       * 显示选择文件夹对话框
       * @param title	 		[string] 可以为空，对话框标题;默认为“选择文件夹”
        return JSON格式
        1）成功返回值：
        {
         "ErrorCode" : 0,
         "ErrorMsg" : "",
             "Result" :
         {
           "filePath":"xxxxx"
         }
         "Status" : 1
        }
        2）失败返回值
        {
            "ErrorCode" : XXX,
            "ErrorMsg" : "xxx",
            "Result" : 0,
            "Status" : 0  // Status=0,代表失败；Status=1，代表成功；Status=2，属于中间状态，代表正在执行
        }
       */
  selectFolderDialog (title) {
    const paramJson = {
      title: title
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.selectFolderDialog(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_SelectFolderDialog', paramJson)
  }

  /**
	 * 修改Key管理员Pin码
	 * @param sDeviceSN  			[string] 硬件序列号
	 * @param sOldPass  			[string] 旧密码
	 * @param sNewPass	 			[string] 新密码
	 * @param succFn 成功的回调函数，上层传入
	 *
	    return JSON格式

		1）成功返回值
		{
			"ErrorCode" : 0,
			"ErrorMsg" : "",
			"Result" : 0,
			"Status" : 1
		}
		2）失败返回值
		{
			"ErrorCode" : xxx,
			"ErrorMsg" : "xxxxx",
			"Result" : 0,
			"Status" : 0
		}
	 *
	 */
  changeAdminPass (sDeviceSN, sOldPass, sNewPass) {
    const paramJson = {
      sDeviceSN: sDeviceSN,
      sOldPass: sOldPass,
      sNewPass: sNewPass
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.changeAdminPass(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('ChangeAdminPass', paramJson)
  }

  /**
      * 修改Key用户Pin码
      * @param sDeviceSN  			[string] 硬件序列号
      * @param sOldPass  			    [string] 旧密码
      * @param sNewPass	 			[string] 新密码
         return JSON格式

         1）成功返回值
         {
             "ErrorCode" : 0,
             "ErrorMsg" : "",
             "Result" : 0,
             "Status" : 1
         }
         2）失败返回值
         {
             "ErrorCode" : xxx,
             "ErrorMsg" : "xxxxx",
             "Result" : 0,
             "Status" : 0
         }
      *
      */
  changeUserPass (sDeviceSN, sOldPass, sNewPass) {
    const paramJson = {
      sDeviceSN: sDeviceSN,
      sOldPass: sOldPass,
      sNewPass: sNewPass
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.ChangeUserPass(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('ChangeUserPass', paramJson)
  }

  /**
     * 解锁用户密码成功
     * @param sDeviceSN  			[string] 硬件序列号
     * @param sAdminPass  			[string] 管理员PIN码
     * @param sNewUserPass	 		[string] 新的用户密码

     *
        return JSON格式

      1）成功返回值
      {
        "ErrorCode" : 0,
        "ErrorMsg" : "",
        "Result" : 0,
        "Status" : 1
      }
      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  unlockUserPass (sDeviceSN, sAdminPass, sNewUserPass) {
    const paramJson = {
      sDeviceSN: sDeviceSN,
      sAdminPass: sAdminPass,
      sNewUserPass: sNewUserPass
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.unlockUserPass(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('UnlockUserPass', paramJson)
  }

  /**
     * 删除容器
     * @param sDeviceSN  			[string] 硬件序列号
     * @param sContainerName  		[string] 容器名称
        return JSON格式
      1）成功返回值
      {
    "ErrorCode": 0,
    "ErrorMsg": "",
    "Result":0,
    "Status": 1
  }

      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  deleteContainer (sDeviceSN, sContainerName) {
    const paramJson = {
      sDeviceSN: sDeviceSN,
      sContainerName: sContainerName
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.deleteContainer(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('DeleteContainer', paramJson)
  }

  /**
     * 校验容器是否存在
     * @param sDeviceSN  			[string] 硬件序列号
     * @param sContainerName  		[string] 容器名称
     *
        return JSON格式

      1）成功返回值
      {
    "ErrorCode": 0,
    "ErrorMsg": "",
    "Result": 0,
    "Status": 1
  }

      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  isContainerExist (sDeviceSN, sContainerName) {
    const paramJson = {
      sDeviceSN: sDeviceSN,
      sContainerName: sContainerName
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.isContainerExist(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('IsContainerExist', paramJson)
  }

  /**
     * 获取所有的设备序列号
     *
        return JSON格式

      1）成功返回值:返回当前存在的可支持的所有设备的序列号，每个设备序列号以分号(;)结尾，没有则返回空值
      {
    "ErrorCode": 0,
    "ErrorMsg": "",
    "Result": {
      "sDeviceSNs": "AC9A4221041B9209;AC9A422104196B09;"
    },
    "Status": 1
  }

      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  getAllDeviceSN () {
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.getAllDeviceSN()
      return this.exeIE(resultJson)
    }
    return this.exeFn('GetAllDeviceSN')
  }

  /**
     * 获取所有的设备个数
     *
        return JSON格式

      1）成功返回值:
      {
     "ErrorCode" : 0,
     "ErrorMsg" : "",
     "Result" : {
        "lDevNum" : 1
     },
     "Status" : 1
  }

      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  getDeviceCount () {
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.getDeviceCount()
      return this.exeIE(resultJson)
    }
    return this.exeFn('GetDeviceCount')
  }

  /**
     * 获取设备中所有容器名称
     * @param sDeviceSN  			[string] 硬件序列号
     *
        return JSON格式

      1）成功返回值:没有则返回空值
      {
    "ErrorCode": 0,
    "ErrorMsg": "",
    "Result": {
      "sContainers": "conNJYZT&&&"
    },
    "Status": 1
  }

      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  getAllContainerName (sDeviceSN) {
    const paramJson = {
      sDeviceSN: sDeviceSN
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.getAllContainerName(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('GetAllContainerName', paramJson)
  }

  /**
     * 获取设备中所有容器个数
     * @param sDeviceSN  			[string] 硬件序列号
     *
        return JSON格式

      1）成功返回值:
      {
     "ErrorCode" : 0,
     "ErrorMsg" : "",
     "Result" : {
        "lConNum" : 1
     },
     "Status" : 1
  }

      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  getContainerCount (sDeviceSN) {
    const paramJson = {
      sDeviceSN: sDeviceSN
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.getContainerCount(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('GetContainerCount', paramJson)
  }

  /**
     * 获取设备信息
     * @param sDeviceSN  			[string] 硬件序列号
     * @param iType  				[long] 要获取的设备信息类型，目前支持的类型如下
                        •0x00000001 设备标签
                        •0x00000002 设备空余空间
                        •0x00000005 获取用户pin码重试次数
                        •0x00000008 对应动态库的名称
                        •0x00000074 设备的VID_PID （16进制数据，例如096E_0401）
     *
        return JSON格式

      1）成功返回值:{
     "ErrorCode" : 0,
     "ErrorMsg" : "",
     "Result" : {
        "sDeviceInfo" : "111872"
     },
     "Status" : 1
  }

      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  getDeviceInfo (sDeviceSN, iType) {
    const paramJson = {
      sDeviceSN: sDeviceSN,
      iType: iType
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.getDeviceInfo(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('GetDeviceInfo', paramJson)
  }

  /**
     * 校验用户PIN码
     * @param sDeviceSN  			[string] 硬件序列号
     * @param sUserPin				[string] 用户PIN码
     *
        return JSON格式

      1）成功返回值:
      {
        "ErrorCode" : 0,
        "ErrorMsg" : "",
        "Result" : ,
        "Status" : 1
      }
      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  verifyUserPin (sDeviceSN, sUserPin) {
    const paramJson = {
      sDeviceSN: sDeviceSN,
      sUserPin: sUserPin
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.verifyUserPin(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('VerifyUserPin', paramJson)
  }

  /**
     * 产生密钥对
     * @param sDeviceSN  			[string] 硬件序列号
     * @param sContainerName		[string] 容器名称
     * @param iKeyType				[LONG ]	 密钥类型 1: RSA 1024 2: RSA 2048 3 SM2 256  ，目前底层只支持3
     * @param bSign					[LONG ]  目前底层只支持1（签名）
     *
        return JSON格式

      1）成功返回值:
      {
        "ErrorCode" : 0,
        "ErrorMsg" : "",
        "Result" : ,
        "Status" : 1
      }
      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  generateKeyPair (sDeviceSN, sContainerName, iKeyType = 3, bSign = 1) {
    const paramJson = {
      sDeviceSN: sDeviceSN,
      sContainerName: sContainerName,
      iKeyType: iKeyType,
      bSign: bSign
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.generateKeyPair(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('GenerateKeyPair', paramJson)
  }

  /**
     * 产生PKCS10
     * @param sDeviceSN  			[string] 硬件序列号
     * @param sContainerName		[string] 容器名称
     * @param sDN					[string] 证书DN项
     * @param bSign					[LONG ]  目前底层只支持1（签名）
     *
        return JSON格式

      1）成功返回值:
      {
     "ErrorCode" : 0,
     "ErrorMsg" : "",
     "Result" : {
        "sP10" : "MIIBNzCB3AIBADB6MQ0wCwYDVQQDDARuYW1lMREwDwYDVQQLDAhCSkNBIFImRDENMAsGA1UECgwEQkpDQTEPMA0GA1UEBwwG5YyX5LqsMQswCQYDVQQIDAJiajELMAkGA1UEBhMCQ04xHDAaBgkqhkiG9w0BCQEWDW1haWxAYmpjYS5jb20wWTATBgcqhkjOPQIBBggqgRzPVQGCLQNCAAQ+Cdzi6936ywgVV77rYI4VljDMpzZjbrxmr25JXXIBTzYFCGb5fqqT/AbEYe87KXWE0PWFYUQC65plVMwlKmlfoAAwDAYIKoEcz1UBg3UFAANIADBFAiAWkNDVtvgLESwn7UGYiYwUNWD9eLptgWZCSJ8lshZr1AIhAJEcWzvCVdKQTfmn6Ro7z3ugsqmozfGBRZI82eEv4C1d"
     },
     "Status" : 1
  }

      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  exportPKCS10 (sDeviceSN, sContainerName, sDN) {
    const paramJson = {
      sDeviceSN: sDeviceSN,
      sContainerName: sContainerName,
      sDN: sDN,
      bSign: 1
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.exportPKCS10(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('ExportPKCS10', paramJson)
  }

  /**
     * 产生PKCS10
     * @param sDeviceSN  			[string] 硬件序列号
     * @param sContainerName		[string] 容器名称
     * @param sDN					[string] 证书DN项
     * @param bSign					[LONG ]  目前底层只支持1（签名）
     *
        return JSON格式

      1）成功返回值:
      {
     "ErrorCode" : 0,
     "ErrorMsg" : "",
     "Result" : {
        "sP10" : "MIIB8TCCAZQCAQAwejENMAsGA1UEAwwEbmFtZTERMA8GA1UECwwIQkpDQSBSJkQxDTALBgNVBAoMBEJKQ0ExDzANBgNVBAcMBuWMl+S6rDELMAkGA1UECAwCYmoxCzAJBgNVBAYTAkNOMRwwGgYJKoZIhvcNAQkBFg1tYWlsQGJqY2EuY29tMFkwEwYHKoZIzj0CAQYIKoEcz1UBgi0DQgAEPgnc4uvd+ssIFVe+62COFZYwzKc2Y268Zq9uSV1yAU82BQhm+X6qk/wGxGHvOyl1hND1hWFEAuuaZVTMJSppX6CBtzATBgkqhkiG9w0BCQcTBjExMTExMTCBnwYJKoZIhvcNAQk/BIGRMIGOAgEBBIGIALQAAAABAAB01XsGD2BRSuJNtAShsKnqJZPZ+h4DUQzpEnnhU6ak7gAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAv2w/G1YnbOIqEhVIjMBqbnMo9kzXBslOx7bqdDAVNiYAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADAMBggqgRzPVQGDdQUAA0kAMEYCIQCFwZs81gkwyOGrpFVgc5L53vqyYKAHNwBelaMrPixOgQIhAPCy4M4I38btKE61B5yRsRvc/5Lk8lO5d21n1IdWjgIy"
     },
     "Status" : 1
  }

      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  exportCFCAPKCS10 (sDeviceSN, sContainerName, sDN) {
    const paramJson = {
      sDeviceSN: sDeviceSN,
      sContainerName: sContainerName,
      sDN: sDN,
      bSign: 1
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.exportCFCAPKCS10(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('ExportCFCAPKCS10', paramJson)
  }

  /**
     * 导入签名证书
     * @param sDeviceSN  			[string] 硬件序列号
     * @param sContainerName		[string] 容器名称
     * @param sCert					[string] Base64编码的签名证书
     *
        return JSON格式

      1）成功返回值:
      {
        "ErrorCode" : 0,
        "ErrorMsg" : "",
        "Result" : ,
        "Status" : 1
      }
      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  importSignCert (sDeviceSN, sContainerName, sCert) {
    const paramJson = {
      sDeviceSN: sDeviceSN,
      sContainerName: sContainerName,
      sCert: sCert
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.importSignCert(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('ImportSignCert', paramJson)
  }

  /**
     * 导入加密证书
     * @param sDeviceSN  			[string] 硬件序列号
     * @param sContainerName		[string] 容器名称
     * @param sCert					[string] Base64编码的加密证书
     * @param sPriKeyCipher			[string] Base64编码的加密密钥密文， 格式符合《SM2密码算法使用规范》“7.4 密钥对保护数据格式”相关要求。
     *
        return JSON格式

      1）成功返回值:
      {
        "ErrorCode" : 0,
        "ErrorMsg" : "",
        "Result" : ,
        "Status" : 1
      }
      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  importEncCert (sDeviceSN, sContainerName, sCert, sPriKeyCipher) {
    const paramJson = {
      sDeviceSN: sDeviceSN,
      sContainerName: sContainerName,
      sCert: sCert,
      sPriKeyCipher: sPriKeyCipher
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.importEncCert(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('ImportEncCert', paramJson)
  }

  /**
     * 获取导出公钥
     * @param sDeviceSN  			[string] 硬件序列号
     * @param sContainerName		[string] 容器名称
     * @param bSign					[long] 0-加密公钥，1-签名公钥
     *
        return JSON格式

      1）成功返回值:没有则返回空值
      {
    "ErrorCode": 0,
    "ErrorMsg": "",
    "Result": {
      "sPubKey": "..."
    },
    "Status": 1
  }

      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  exportPubKey (sDeviceSN, sContainerName, bSign) {
    const paramJson = {
      sDeviceSN: sDeviceSN,
      sContainerName: sContainerName,
      bSign: bSign
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.exportPubKey(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('ExportPubKey', paramJson)
  }

  /**
     * 获取导出证书主题项
     * @param sDeviceSN  			[string] 硬件序列号
     *
        return JSON格式

      1）成功返回值:没有则返回空值
      {
    "ErrorCode": 0,
    "ErrorMsg": "",
    "Result": {
      "sCertDn": "..."
    },
    "Status": 1
  }

      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  getCertDn (sDeviceSN) {
    const paramJson = {
      sDeviceSN: sDeviceSN
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.getCertDn(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('GetCertDn', paramJson)
  }

  /**
    * 判断Ukey中是否存在证书
    * @param sDeviceSN  			[string] 硬件序列号
    * @param succFn 成功的回调函数，上层传入
    * @param failFn 失败的回调函数，上层传入
    *
       return JSON格式

       1）成功返回值:没有则返回空值
       {
       "ErrorCode": 0,
       "ErrorMsg": "",
       "Result": {
               "bExist": true   //true:存在证书； false：不存在
       },
       "Status": 1
       }

       2）失败返回值
       {
               "ErrorCode" : xxx,
               "ErrorMsg" : "xxxxx",
               "Result" : 0,
               "Status" : 0
       }
   *
   */
  isExistCert (sDeviceSN) {
    const paramJson = {
      sDeviceSN: sDeviceSN
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.IsExistCert(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('IsExistCert', paramJson)
  }

  /**
     * 写文件到USB key中
     * @param sDeviceSN  			[string] 硬件序列号
     * @param sFileName  			[string] 文件名称
     * @param sContent  			[string] 文件内容
     * @param bPrivate  			[long] 传入0, 内部没有使用
     *
        return JSON格式

      1）成功返回值:没有则返回空值
      {
    "ErrorCode": 0,
    "ErrorMsg": "",
    "Result": 0,
    "Status": 1
  }

      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  writeFile (sDeviceSN, sFileName, sContent) {
    const paramJson = {
      sDeviceSN: sDeviceSN,
      sFileName: sFileName,
      sContent: sContent,
      bPrivate: 0
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.writeFile(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('WriteFile', paramJson)
  }

  /**
      * 写文件到USB key中( base64形式，底层会对base64解码，然后写入二进制数据到key中)
      * @param sDeviceSN  			[string] 硬件序列号
      * @param sFileName  			[string] 文件名称
      * @param sContent  			[string] 文件内容(base形式）
      * @param bPrivate  			[long] 传入0, 内部没有使用
      *
         return JSON格式

         1）成功返回值:没有则返回空值
         {
     "ErrorCode": 0,
     "ErrorMsg": "",
     "Result": 0,
     "Status": 1
  }

         2）失败返回值
         {
             "ErrorCode" : xxx,
             "ErrorMsg" : "xxxxx",
             "Result" : 0,
             "Status" : 0
         }
      *
      */
  writeFileFromBase64 (sDeviceSN, sFileName, sContent) {
    const paramJson = {
      sDeviceSN: sDeviceSN,
      sFileName: sFileName,
      sContent: sContent,
      bPrivate: 1
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.WriteFileFromBase64(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('WriteFileFromBase64', paramJson)
  }

  /**
     * 从USBkey中读取文件信息
     * @param sDeviceSN  			[string] 硬件序列号
     * @param sFileName  			[string] 文件名称
     *
        return JSON格式

      1）成功返回值:没有则返回空值
      {
    "ErrorCode": 0,
    "ErrorMsg": "",
    "Result": 0,
    "Status": 1
  }

      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  readFile (sDeviceSN, sFileName) {
    const paramJson = {
      sDeviceSN: sDeviceSN,
      sFileName: sFileName
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.readFile(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('ReadFile', paramJson)
  }

  /**
      * 从USBkey中读取文件信息(返回结果是key里文件内容的base64编码）
      * @param sDeviceSN  			[string] 硬件序列号
      * @param sFileName  			[string] 文件名称
      *
         return JSON格式

         1）成功返回值:没有则返回空值
         {
     "ErrorCode": 0,
     "ErrorMsg": "",
     "Result": 0,
     "Status": 1
  }

         2）失败返回值
         {
             "ErrorCode" : xxx,
             "ErrorMsg" : "xxxxx",
             "Result" : 0,
             "Status" : 0
         }
      *
      */
  readFileToBase64 (sDeviceSN, sFileName) {
    const paramJson = {
      sDeviceSN: sDeviceSN,
      sFileName: sFileName
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.ReadFileToBase64(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('SOF_GetSIMPPReadFileToBase64icture', paramJson)
  }

  /**
     * 标签打印
     * @param sJson		  [string] 传入的数据，格式如：[{"Label1":"HT00A10001","Label2":"江苏智慧数字认证有限公司@1","Label3":"江苏智慧数字认证有限公司"},{"Label1":"HT00A10002","Label2":"江苏智慧数字认证有限公司@2","Label3":"江苏智慧数字认证有限公司@2"}]
     *
        return JSON格式

      1）成功返回值:没有则返回空值
      {
    "ErrorCode": 0,
    "ErrorMsg": "",
    "Result": 0,
    "Status": 1
  }

      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  printLabel (sJson) {
    const paramJson = {
      sJson: sJson
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.printLabel(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('PrintLabel', paramJson)
  }

  /**
     * 打印密码信封
     * @param sJson		  [string] 传入的数据，格式如：{"CertType":"企业证书","CertName":"南京壹证通信息科技有限公司@1","PrintDate":"2019-09-18", "BarCode":"012345678902", "Pwd":"111111"}
        return JSON格式

      1）成功返回值:没有则返回空值
      {
    "ErrorCode": 0,
    "ErrorMsg": "",
    "Result": 0,
    "Status": 1
  }

      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  printPwdLabel (sJson) {
    const paramJson = {
      sJson: sJson
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.printPwdLabel(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('PrintPwdLabel', paramJson)
  }

  /**
     * 获取最新错误信息 理论不需要调用该接口，所有的业务接口发生错误时，底层已经自动调用GetLastError（），并返回了错误信息
        return JSON格式
      1）成功返回值:
      {
     "ErrorCode" : 0,
     "ErrorMsg" : "",
     "Result" : {
        "ErrorCode" : 0,
        "ErrorMsg" : "成功"
     },
     "Status" : 1
  }

      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  getLastError () {
    // if (this.isUseOcx()) {
    //   const resultJson = CommponResponse.FAIL("9000", "不支持IE浏览器");
    //
    // }
    return this.exeFn('GetLastError')
  }

  /// //////////////////////////////////////CSP 发证接口////////////////////////////////////////////////////////////
  /**
	 * 如果已有密钥对, 生成CSR
	 * @param tokenName		  	[string]  	CSP name
	 * @param containerName		[string]  	容器名称
	 * @param userPIN		  	[string]  	可以为空， 用户PIN码
	 * @param uniqid		  	[string]  	调用者自定义的字符串, 以CN项的形式包含在生成的CSR里面
	 *
	    return JSON格式

		1）成功返回值:返回NEWCSR
		{
			"ErrorCode": 0,
			"ErrorMsg": "",
			"Result":
				{
					"NewCSR":"..............."
				}
			"Status": 1
		}

		2）失败返回值
		{
			"ErrorCode" : xxx,
			"ErrorMsg" : "xxxxx",
			"Result" : 0,
			"Status" : 0
		}
	 *
	 */
  CSP_GenerateCSR (tokenName, containerName, userPIN, uniqid) {
    const paramJson = {
      tokenName: tokenName,
      containerName: containerName,
      userPIN: userPIN,
      uniqid: uniqid
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.CSP_GenerateCSR(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('CSP_GenerateCSR', paramJson)
  }

  /**
     * 生成密钥对
     * @param tokenName		  	[string]  	CSP name
     * @param containerName		[string]  	容器名称
     * @param userPIN		  	[string]  	可以为空， 用户PIN码
     * @param keyType			[int]		密钥类型, 始终是1 (RSA)
     * @param keyBits			[int]		密钥长度, 1024, 2048
     *
        return JSON格式

      1）成功返回值:
      {
        "ErrorCode": 0,
        "ErrorMsg": "",
        "Result": 0
        "Status": 1
      }

      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  CSP_GenerateKeyPair (tokenName, containerName, userPIN, keyType, keyBits) {
    const paramJson = {
      tokenName: tokenName,
      containerName: containerName,
      userPIN: userPIN,
      keyType: 1,
      keyBits: keyBits
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.CSP_GenerateKeyPair(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('CSP_GenerateKeyPair', paramJson)
  }

  /**
     * 如果已有密钥对, 根据输入的XML定义, 生成CSR
     * @param tokenName		  	[string]  	CSP name
     * @param containerName		[string]  	容器名称
     * @param userPIN		  	[string]  	可以为空， 用户PIN码
     * @param xml	 		  	[string]  	xml XML格式的字符串, 其中包含CSR主题项内容, 格式如下：
                        <p10>
                        <subject>
                        <item>
                        <oid>C</oid>
                        <val>CN</val>
                        </item>
                        <item>
                        <oid>2.5.4.3</oid>
                        <val>TestForGeneralOid</val>
                        </item>
                        <item>
                        <oid>2.5.4.1.8.7.6.5.4</oid>
                        <val>TestForCustomerOid</val>
                        </item>
                        </subject>
                        </p10>

     *
        return JSON格式

      1）成功返回值:返回NEWCSR
      {
        "ErrorCode": 0,
        "ErrorMsg": "",
        "Result":
          {
            "NewCSR":"..............."
          }
        "Status": 1
      }

      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  CSP_GenerateP10 (tokenName, containerName, userPIN, xml) {
    const paramJson = {
      tokenName: tokenName,
      containerName: containerName,
      userPIN: userPIN,
      xml: xml
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.CSP_GenerateP10(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('CSP_GenerateP10', paramJson)
  }

  /**
     * 导入证书
     * @param tokenName		  	[string]  	CSP name
     * @param containerName		[string]  	容器名称
     * @param userPIN		  	[string]  	可以为空， 用户PIN码
     * @param certa	 		  	[string]  	Base64编码的签名证书
     * @param ekeyblob	 		[string]  	Base64编码的加密证书私钥. 可以为""
     * @param certe	 		  	[string]  	Base64编码的加密证书. 可以为""
     *
        return JSON格式

      1）成功返回值:返回NEWCSR
      {
        "ErrorCode": 0,
        "ErrorMsg": "",
        "Result": 0
        "Status": 1
      }

      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  CSP_Install (tokenName, containerName, userPIN, certa, ekeyblob, certe) {
    const paramJson = {
      tokenName: tokenName,
      containerName: containerName,
      userPIN: userPIN,
      certa: certa,
      ekeyblob: ekeyblob,
      certe: certe
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.CSP_Install(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('CSP_Install', paramJson)
  }

  /**
     * 检查指定密钥是否已经存在
     * @param tokenName		  	[string]  	CSP name
     * @param containerName		[string]  	容器名称
     * @param signFlag	 		[int]  		signFlag 签名证书: 1, 加密证书:２
     *
        return JSON格式

      1）成功返回值:返回NEWCSR
      {
        "ErrorCode": 0,
        "ErrorMsg": "",
        "Result": 0
        "Status": 1
      }

      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  CSP_KeyExists (tokenName, containerName, signFlag) {
    const paramJson = {
      tokenName: tokenName,
      containerName: containerName,
      signFlag: signFlag
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.CSP_KeyExists(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('CSP_KeyExists', paramJson)
  }

  /**
     * 更新证书
     * @param tokenName		  	[string]  	CSP name
     * @param containerName		[string]  	容器名称
     * @param userPIN		  	[string]  	可以为空， 用户PIN码
     * @param certa	 		  	[string]  	Base64编码的签名证书
     * @param certe	 		  	[string]  	Base64编码的加密证书. 可以为""
     *
        return JSON格式

      1）成功返回值:返回NEWCSR
      {
        "ErrorCode": 0,
        "ErrorMsg": "",
        "Result": 0
        "Status": 1
      }

      2）失败返回值
      {
        "ErrorCode" : xxx,
        "ErrorMsg" : "xxxxx",
        "Result" : 0,
        "Status" : 0
      }
     *
     */
  CSP_RenewCertificate (tokenName, containerName, userPIN, certa, certe) {
    const paramJson = {
      tokenName: tokenName,
      containerName: containerName,
      userPIN: userPIN,
      certa: certa,
      certe: certe
    }
    if (this.isUseOcx()) {
      // 如果是IE内核
      const resultJson = this.smartCAIE.CSP_RenewCertificate(paramJson)
      return this.exeIE(resultJson)
    }
    return this.exeFn('CSP_RenewCertificate', paramJson)
  }

  DeleteUkeyFile(sDeviceSN, sFileName) {
    const paramJson = {
        sDeviceSN: sDeviceSN,
        sFileName: sFileName
    };
    if (this.isUseOcx()) {
      //如果是IE内核
      const resultJson = this.smartCAIE.deleteUkeyFile(paramJson);
      return this.exeIE(resultJson);
    }
    return this.exeFn("DeleteUkeyFile", paramJson);
}

  	 /**
	 * 获取ukey中所有文件
	 * @param sDeviceSN  			[string] 硬件序列号
	 *
	    return JSON格式
		
		1）成功返回值:没有则返回空值 
{
   "ErrorCode" : 0,
   "ErrorMsg" : "",
   "Result" : {
      "sFiles" : "sealSpec;sealSpec_1;sealSpec_4"
   },
   "Status" : 1
}

		2）失败返回值
		{
			"ErrorCode" : xxx,
			"ErrorMsg" : "xxxxx",
			"Result" : 0,
			"Status" : 0
		}
	 *
	 */
    GetAllFiles(sDeviceSN) {

      var paramJson = {
          sDeviceSN: sDeviceSN
      };
      if (this.isUseOcx()) {
        //如果是IE内核
        const resultJson = this.smartCAIE.getAllFiles(paramJson);
        return this.exeIE(resultJson);
      }
      return this.exeFn("GetAllFiles", paramJson);
  
    }
}

export default SmartCAPKI
