
import request from "./request";
const defaultParams = {
    method: 'GET',
    data: ''
}
const api = (url, data = defaultParams) => {
    return new Promise((resolve, reject) => {
        switch (data.method) {
            case 'POST':
                request.post(url, data.params).then(res => {
                    resolve(res.data)
                }).catch(error => reject(error))
                break;
            case 'DELETE':
                request.delete(url, data.params).then(res => {
                    resolve(res)
                }).catch(error => reject(error))
                break;
            case 'PUT':
                request.put(url, data.params).then(res => {
                    resolve(res)
                }).catch(error => reject(error))
                break;
            default:
                request.get(url, {
                    params: data.params
                }).then(res => {
                    resolve(res.data)
                }).catch(error => reject(error))
                break;
        }
    })
}

export default api