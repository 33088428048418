import axios from "axios";
import { Message,MessageBox,Notification} from 'element-ui'
//判断是开发还是生产模式
let api_base_url = '';
if (process.env.NODE_ENV === 'development') {
    api_base_url = '/biz'
} else {
    api_base_url = '/api/biz'
}

const request = axios.create({
    baseURL: api_base_url,
    timeout: 5000
})
request.interceptors.request.use((config) => {
    // const token = getToken()
    // if (!isEmpty(token)) {
    //     config.headers.Authorization = token
    // }
    return config
}, (error) => {
    Promise.error(error)
})

request.interceptors.response.use((response) => {

    if (response.status == 401) {
        Message({
            message: '您的信息以过期,请重新登录',
            type: 'error'
        })
    }
    if (response.status == 404) {
        Message({
            message: '地址有误',
            type: 'error'
        })
    }
    if (response.status == 403) {
        Message({
            message: '没有权限',
            type: 'error'
        })
    }
    return response
},
error => {
  if (error.message?.indexOf('timeout') > -1) {
    Notification.error({
      title: '请求超时'
    })
    return Promise.reject(error)
  }
  const { response } = error
  const { data, config } = response
  if(data.code==20900005||data.code==20900002||data.code==20900004||data.code==20900003){
  setTimeout(()=>{
    if(process.env.VUE_APP_ENV== 'production'){
      location.href='https://online.smartcert.cn'
    }else{
      location.href='http://online.ca.demo.smartcert.cn'
    }
    MessageBox.close()
  },1000)

    MessageBox.alert('延期跳转中，请稍等', {
      showConfirmButton:false,
      showClose:false
      });
    return

  }
  // 统一的异常提示，当ignoreError为true是表示不需要异常提示，自行处理异常
  if (config.ignoreError) {
    return Promise.reject(response)
  }
  // TODO 其他特殊状态吗的异常处理

  Notification.error({
    title: '请求错误',
    message: data.message || '请求异常'
  })

  return Promise.reject(response)
}
)

export default request